window.jQuery = window.$ = require('jquery');
var processInclude = require('base/util');

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('base/components/cookie'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/header'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('base/components/collapsibleItem'));
    processInclude(require('core/components/search'));
    processInclude(require('base/components/clientSideValidation'));
    processInclude(require('base/components/countrySelector'));
    processInclude(require('base/components/toolTip'));
    processInclude(require('./accelerator/slider'));
    processInclude(require('./product/quickView'));
    processInclude(require('./bulbFinder/bulbFinder'));
    processInclude(require('./thirdParty/gtm'));
    processInclude(require('./components/signUpNewsletter'));
});

require('core/thirdParty/bootstrap');
require('base/components/spinner');
require('core/thirdParty/lazysizes');
require('core/thirdParty/smartResize');
require('core/thirdParty/hoverIntent');
require('select2');
