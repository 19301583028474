'use strict';

module.exports = function () {
    var $window = $(window),
        $body = $('body'),
        $hbHeight = 0;

    if($('.header-banner').length > 0) {
        $hbHeight = $('.header-banner').height();
    }

    $window.on('scroll', function () {
        if($window.scrollTop() > $hbHeight) {
            $body.addClass('sticky-header');
            $('#header-placeholder').addClass('shown');
        } else {
            $body.removeClass('sticky-header');
            $('#header-placeholder').removeClass('shown');
        }
    });

    $('body').on('click', '.search .site-search #shownButton', function (e) {
        e.preventDefault();
    });
};
