'use strict';

require('core/thirdParty/slick');

module.exports = function () {
    $('.slider').slick({
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1
    });

    $('.product-slider').slick({
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: false,
                    arrows: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true
                }
            },
            {
                breakpoint: 544,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    arrows: true
                }
            }
        ]
    });

	if ($('.top-promotion-banner-slider').length > 0) {
		const autoplaySpeed = $('.top-promotion-banner-slider').data('autoplayspeed') || 4000;
		$('.top-promotion-banner-slider').slick({
			autoplay: true,
			autoplaySpeed: autoplaySpeed,
			dots: false,
			arrows: false,
			infinite: true,
			slidesToShow: 1,
			slidesToScroll: 1
		})
	}
    $('.product-carousel-slider').slick();
};
