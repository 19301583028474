'use strict';

var scrollAnimate = require('base/components/scrollAnimate');

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked for email sign-up
 */
function displayMessage(data, button) {
    $.spinner().stop();
    var status;
    if (data.success) {
        status = 'alert-success';
    } else {
        status = 'alert-danger';
    }

    if ($('.email-signup-message').length === 0) {
        $('body').append(
           '<div class="email-signup-message"></div>'
        );
    }
    $('.email-signup-message')
        .append('<div class="email-signup-alert text-center ' + status + '">' + data.msg + '</div>');

    setTimeout(function () {
        $('.email-signup-message').remove();
        button.removeAttr('disabled');
    }, 3000);
}

module.exports = function () {
    $('.back-to-top').click(function () {
        scrollAnimate();
    });

    $('form.joinlist-bulb-finder-form').submit(function (e) {
        var form = $(this);
        var emailId = form.find('input[name=hpEmailSignUp]').val();
        var $message = form.find('span.message');
        var bulbFinderObj = {};
        bulbFinderObj.year = form.find('#jmlBulbFinderYear').val();
        bulbFinderObj.make = form.find('#select2-jmlBulbFinderMake-container').text().trim();
        bulbFinderObj.model = form.find('#select2-jmlBulbFinderModel-container').text().trim();
        e.preventDefault();
        var url = form.attr('action');
        form.parents('.modal-body').spinner().start();

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId,
                bulbFinderObj: JSON.stringify(bulbFinderObj)
            },
            success: function (data) {
                if (data.error) {
                    $message.text(data.msg);
                } else {
                    $('#signUpModal').modal('hide');
                    $('#signUpThankYouModal').modal('show');
                }
                form.parents('.modal-body').spinner().stop();
            },
            error: function (data) {
                if (data.error) {
                    $message.text(data.msg);
                }
                form.parents('.modal-body').spinner().stop();
            }
        });
    });

    $('.subscribe-email').on('click', function (e) {
        e.preventDefault();
        var url = $(this).data('href');
        var button = $(this);
        var emailId = $('input[name=hpEmailSignUp]').val();
        var $parentModal = button.closest('.signup-modal');
        var $message = $parentModal.find('span.message');
        $.spinner().start();
        $(this).attr('disabled', true);

        // Sign Up Modal
        if (!!$parentModal.length) {
            emailId = $parentModal.find('input[name=hpEmailSignUp]').val();
            $message.text('');
        }

        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: {
                emailId: emailId
            },
            success: function (data) {
                if (!!$parentModal.length) {
                    if (data.success) {
                        $('#signUpModal').modal('hide');
                        $('#signUpThankYouModal').modal('show');
                    } else {
                        $message.text(data.msg);
                    }

                    $.spinner().stop();
                } else {
                    displayMessage(data, button);
                    $('.footer-email-signup-content .form-control').val('');
                }
            },
            error: function (err) {
                if (!!$parentModal.length) {
                    $message.text(err.msg);
                    $.spinner().stop();
                } else {
                    displayMessage(err, button);
                }
            }
        });
    });

    // Fixes 404 if href is equal to null # or empty
    $('body').on('click', 'a', function (e) {
        var $this = $(this);
        var href = $this.attr('href');
        if (href == null || $.trim(href) == '' || href == '#' || href == 'root' || href == 'null') {
            e.preventDefault();
        }
    });
};
