'use strict';

var focusHelper = require('base/components/focus');

/**
 * Renders a modal window that will track the users consenting to accepting site tracking policy
 */
function showConsentModal() {
    if (!$('.tracking-consent').data('caonline')) {
        return;
    }

    var urlContent = $('.tracking-consent').data('url');
    var urlAccept = $('.tracking-consent').data('accept');
    var urlReject = $('.tracking-consent').data('reject');
    var textYes = $('.tracking-consent').data('accepttext');
    var textNo = $('.tracking-consent').data('rejecttext');
    var textHeader = $('.tracking-consent').data('heading');

    $.spinner().start();

    $('body').append(getConsentTrackingContainer(urlAccept, urlReject, textYes, textNo));

    $.ajax({
        url: urlContent,
        type: 'get',
        dataType: 'html',
        success: function (response) {
            $('.consent-tracking--footer .consent-tracking--copy').html(response);
            $.spinner().stop();
        },
        error: function () {
            $('.consent-tracking--footer').remove();
            $.spinner().stop();
        }
    });

    $('body').on('click', '.consent-tracking--footer button.affirm', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('.consent-tracking--footer').remove();
                $.spinner().stop();
            },
            error: function () {
                $('.consent-tracking--footer').remove();
                $.spinner().stop();
            }
        });
    });

    $('body').on('click', '.consent-tracking--footer button.decline', function (e) {
        e.preventDefault();
        var url = $(this).data('url');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function () {
                $('.consent-tracking--footer').remove();
                $.spinner().stop();
            },
            error: function () {
                $('.consent-tracking--footer').remove();
                $.spinner().stop();
            }
        });
    });

    $('body').on('click', '.consent-tracking--footer button[data-dismiss="modal"]', function (e) {
        $('.consent-tracking--footer').remove();
    });
}

function getConsentTrackingContainer(urlAccept, urlReject, textYes, textNo) {
    return [
        '<div class="consent-tracking--footer">',
        '   <div class="container">',
        '       <div class="consent-tracking--copy"></div>',
        '       <button class="affirm btn btn-primary" data-url="'+urlAccept+'" data-dismiss="modal">'+textYes+'</button>',
        '       <button class="decline btn btn-primary" data-url="'+urlReject+'" data-dismiss="modal" >'+textNo+'</button>',
        '       <button type="button" data-url="'+urlReject+'" class="close decline" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>',
        '   </div>',
        '</div>'
    ].join('');
}

module.exports = function () {
    if ($('.consented').length === 0 && $('.declined').length === 0 && $('.tracking-consent').hasClass('api-true')) {
        showConsentModal();
    }

    if ($('.tracking-consent').hasClass('api-true')) {
        $('.tracking-consent').click(function () {
            showConsentModal();
        });
    }

    $('body').on('shown.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'true');
        $('#consent-tracking .close').focus();
    });

    $('body').on('hidden.bs.modal', '#consent-tracking', function () {
        $('#consent-tracking').siblings().attr('aria-hidden', 'false');
        $.spinner().stop();
    });

    $('body').on('keydown', '#consent-tracking', function (e) {
        var focusParams = {
            event: e,
            containerSelector: '#consent-tracking',
            firstElementSelector: '.affirm',
            lastElementSelector: '.decline',
            nextToLastElementSelector: '.affirm'
        };
        focusHelper.setTabNextFocus(focusParams);
    });
};
