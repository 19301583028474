'use strict';

var bulbPositionsResult = $('#positions').data('positions');
var formValidation = require('base/components/formValidation'); 
var loaded = false;
/**
 * Function to refresh token for each Request
 * @param {*} action ActionType
 * @returns {*} captchaPromise
 */
function getNewToken(action) {
    var recaptcha = $('input[name="g-recaptcha-response"]');
    if(recaptcha.length){
        return new Promise(function (resolve) {
            if (recaptcha && recaptcha.length > 0) {
                var siteKey = $('input[name="g-recaptcha-response"]').data('sitekey');
                grecaptcha.ready(function () {
                    grecaptcha.execute(siteKey, { action: action }).then(function (token) {
                        recaptcha.val(token);
                        resolve('success');
                    });
                });
            }
        });
    }
    return Promise.resolve('success');
}

/**
 * Update DOM elements with Ajax results
 *
 * @param {Object} $results - jQuery DOM element
 * @param {string} selector - DOM element to look up in the $results
 *
 */
function updateDom($results, selector) {
    var $updates = $results.find(selector);
    $(selector).empty().html($updates.html());
}

function myHandler(e) {			
    console.log('addEventListener');
    $('.how-to-carousel-slider').slick('slickNext');
}

/**
 * Keep refinement panes expanded/collapsed after Ajax refresh
 *
 * @param {Object} $results - jQuery DOM element
 * @return {undefined}
 */
function handleRefinements($results) {
    $('.refinement.active').each(function () {
        $(this).removeClass('active');
        var activeDiv = $results.find('.' + $(this)[0].className.replace(/ /g, '.'));
        activeDiv.addClass('active');
        activeDiv.find('button.title').attr('aria-expanded', 'true');
    });

    updateDom($results, '.refinements');
}

/**
 * Parse Ajax results and updated select DOM elements
 *
 * @param {string} response - Ajax response HTML code
 * @return {undefined}
 */
function parseResults(response) {
    var $results = $(response);
    var specialHandlers = {
        '.refinements': handleRefinements
    };

    // Update DOM elements that do not require special handling
    [
        '.grid-header',
        '.header-bar',
        '.header.page-title',
        '.product-grid',
        '.show-more',
        '.filter-bar'
    ].forEach(function (selector) {
        updateDom($results, selector);
    });

    Object.keys(specialHandlers).forEach(function (selector) {
        specialHandlers[selector]($results);
    });
}
/**
 * Function to refine Cateory Landing page on Pageload
 * @param {*} e Event
 */
function applyFilterOnStoredPartNumbers(e) {
    $.spinner().start();
    var url = $('#partNumberList').data('url');
    $.ajax({
        url: url,
        data: {
            page: $('.grid-footer').data('page-number'),
            selectedUrl: $(this).data('href')
        },
        method: 'GET',
        success: function (response) {
            parseResults(response);
            $.spinner().stop();
        },
        error: function () {
            $.spinner().stop();
        }
    });
}
/**
 *This function to sort based on given  format
 * @param {*} array Array
 * @returns {*} arr sorted Array
 */
function sortLocations(array) {
    var sortBy = ['forward lighting', 'exterior lighting', 'interior lighting'];
    return array.sort(function (a, b) {
        return sortBy.indexOf(a.toLowerCase()) - sortBy.indexOf(b.toLowerCase());
    });
}
function getRandom(){
	try{
        return window.crypto.randomUUID();
    }catch(ex){
        return Math.floor(Math.random()*Number.MAX_SAFE_INTEGER);
	}
}
/**
 * Function to create request to populate dropdowns
 * @param {*} lookUpType Lookup request
 * @returns {*} request RequestObject
 */
function createRequest(lookUpType, thisForm) {
    var $this = thisForm.length > 0 ? thisForm : $('.bulb-finder-form');
    var request = {};
    var radioButtonVal = $this.find('input[name="radio"]:checked').val();
    var captchaResponse = $('input[name="g-recaptcha-response"]').val();
    if (captchaResponse) {
        request.captchaResponse = captchaResponse;
    }
    request.lookupType = lookUpType;
    var year = $this.find('select[name="bulbFinderYear"]').val();
    var manufacturerId = $this.find('select[name="bulbFinderMake"]').val();
    var notesId = $this.find('select[name="bulbFinderNotes"]').val();
    var modelId = $this.find('select[name="bulbFinderModel"]').val();
    var carId = $('.bulb-finder-form').find('input[name="carId"]').val();
    var oepn = radioButtonVal !== undefined ? radioButtonVal : $this.find('input[name="oepn"]').val();
    var useId = $this.find('select[name="bulbFinderPositions"]').val();
    var positionName = $this.find('select[name="bulbFinderPositions"] option:selected').text().trim();
    var positionId = $this.find('select[name="bulbFinderPositions"]').val();
    var location = $this.find('select[name="bulbFinderLocations"]').val();
    var partNumber = $('#partNumber').val();
    var makeName = $this.find('select[name="bulbFinderMake"] option:selected').text().trim();
    var modelName = $this.find('select[name="bulbFinderModel"] option:selected').text().trim();
    var selectedRadioBtnText = $this.find('input[name="notes"]').val().length > 0 ? $('input[name="notes"]').val() : '';
    var pageloadID = $this.find('#pageloadID').val();
    if(""==pageloadID){
        pageloadID = $this.find('#pageloadID').val(getRandom()).val();
    }
    request.requestID=pageloadID+"|"+$this.find('#requestID').val(getRandom()).val();
    switch (lookUpType) {
        case 'makes':
            request.constructionYear = year;
            break;
        case 'models':
            request.constructionYear = year;
            request.manufacturerId = manufacturerId;
            break;
        case 'notes':
            request.carId = carId;
            request.useId = useId;
            request.location = location;
            request.notes = selectedRadioBtnText;
            break;
        case 'positions':
            request.carId = carId;
            break;
        case 'car_id':
            request.constructionYear = year;
            request.manufacturerId = manufacturerId;
            request.modelId = modelId;
            request.makeName = makeName;
            request.modelName = modelName;
            break;
        case 'bulbs':
            request.carId = carId;
            request.oepn = oepn;
            request.positionName = positionName;
            request.notes = selectedRadioBtnText;
            break;
        case 'bulbsByPartNumber':
            request.partNumber = partNumber;
            break;
        case 'pagedata':
            request.constructionYear = year;
            request.manufacturerId = manufacturerId;
            request.carId = carId;
            request.location = location;
            request.oepn = oepn;
            request.notes = selectedRadioBtnText;
            request.notesId = notesId;
            request.constructionYear = year;
            request.manufacturerId = manufacturerId;
            request.modelId = modelId;
            request.positionId = positionId;
            request.positionName = positionName;
            request.makeName = makeName;
            request.modelName = modelName;
            break;
        default:
        	throw new RangeError("Invalid lookUpType: "+lookUpType);
    }
    return request;
}
/**
 * Function to populate Bulb location
 * @param {*} result service Response
 */
function populateBulbLocation(result) {
    var temp = [];
    result.forEach(function (object) {
    	try{
            if (temp.indexOf(object.pos_name) === -1) {
                temp.push(object.pos_name.trim());
            }
    	}catch(ex){
        	console.log("Request "+$('#requestID').val()+" Result: "+JSON.stringify(result));
            console.error("Bad object for dropdown: "+JSON.stringify(object));
        }
    });
    temp = sortLocations(temp);
    temp.forEach(function (location) {
        $('#bulbFinderLocations').append($('<option></option>').attr('value', location).text(location));
    });
}
/**
 * Function to populate bulbLocations based on Positions
 * @param {*} positionName positionName
 */
function populateBulbPositionsBasedOnLocations(positionName) {
    var bulbPositions = $('select[name="bulbFinderPositions"]').empty();
    bulbPositions.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
    bulbPositions.removeAttr('disabled');
    var positionArr = [];
    positionArr = bulbPositionsResult;
    positionArr = positionArr.filter(function (positionObj) {
        return positionObj.pos_name === positionName;
    });
    positionArr.forEach(function (object) {
        $('select[name="bulbFinderPositions"]').append($('<option></option>').attr('value', object.use_id).text(object.use_name.trim()));
    });
}
/**
 * Function to populate Notes Drop down
 * @param {*} result  Ajax Response
 * @param {*} selector Jquery Selector
 */
 function bulbNotes(result, selector) {
    var oepns = [];
    if (result && result.length > 0) {
        result.forEach(function (object) {
            if (object.note) {
                selector.append($('<option></option>').attr('value', object.oepn).text(object.note.trim()));
                oepns.push(object.oepn);
            } else if (oepns.indexOf(object.oepn) === -1) {
                $('input[name="oepn"]').val(object.oepn);
                $('input[name="notes"]').val('');
                oepns.push(object.oepn);
            };
        });
    };
}
/**
 * Function to populate the dropdowns
 * @param {*} result  Values to be appended
 * @param {*} selector  where to be appended
 */
function populateDropDowns(result, selector) {
    if (selector.length > 0 && selector[0].id === 'bulbFinderLocations') {
        bulbPositionsResult = result;
        populateBulbLocation(result);
    } else if (selector.length > 0 && selector[0].id === 'bulbFinderPositions') {
        result.forEach(function (object) {
            selector.append($('<option></option>').attr('value', object.use_id).text(object.use_name.trim()));
        });
    } else if (selector.length > 0 && selector[0].id === 'bulbFinderNotes') {
        bulbNotes(result, selector);
    } else {
        result.forEach(function (object) {
            selector.append($('<option></option>').attr('value', object.id).text(object.name.trim()));
        });
    }
}
/**
 * Function to Show Notes Modal
 */
function showNotesModal() {
    var content = $('#bulbNotesModal').html();
    var notesHeader = $('#bulbNotesModal').data('header');
    var htmlString = '<!-- Modal -->'
        + '<div class="modal show" id="notes-modal" aria-modal="true" role="dialog" style="display: block;">'
        + '<div class="modal-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '<div class="modal-title">' + notesHeader + '</div>'
        + '<button type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
        + '</div>'
        + '<div class="modal-body">' + content + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';

    if ($('#notes-modal').length === 0) {
        $('body').append(htmlString);
        $('select[name="bulbFinderNotes"]').empty();
    } else {
        $('#notes-modal .modal-body').empty().append(content);
        $('select[name="bulbFinderNotes"]').empty();
    }

    $('#notes-modal').modal({ backdrop: 'static', keyboard: false });

    $('#notes-modal label').on('click', function () {
        var radioButtonVal = $(this).text();
        if (radioButtonVal[0] === '(' && radioButtonVal[radioButtonVal.length - 1] === ')') {
            radioButtonVal = radioButtonVal.substring(1, radioButtonVal.length - 1);
        }
        $('input[name="notes"]').val(radioButtonVal);
        $('#notes-modal').modal('hide');
    });

    $('#notes-modal .close').on('click', function () {
        if ($('#notes-modal :checked').length > 0) {
            var radioButtonVal = $('input[name="radio"]:checked').val();
            $('input[name="oepn"]').val(radioButtonVal);
            $('#notes-modal').modal('hide');
        } else {
            var notesRequiredContent = $('#bulbNotesRequiredModal').html();
            var notesRequiredModalHtml = '<!-- Modal -->'
            + '<div class="modal show" id="notes-required-modal" aria-modal="true" role="dialog" style="display: block;">'
            + '<div class="modal-dialog">'
            + '<!-- Modal content-->'
            + '<div class="modal-content">'
            + '<div class="modal-body">' + notesRequiredContent + '</div>'
            + '</div>'
            + '</div>'
            + '</div>';

            if ($('#notes-required-modal').length === 0) {
                $('body').append(notesRequiredModalHtml);
            }
            $('#notes-required-modal').modal({ backdrop: 'static', keyboard: false });

            $('.make-selection').on('click', function () {
                $('#notes-required-modal').modal('hide');
            });

            $('.return-to-bulb-finder').on('click', function () {
                $('#notes-required-modal').modal('hide');
                $('#notes-modal').modal('hide');
            });
        }
    });
}
/**
 * Function to populate Notes Radio Drop down
 * @param {*} result  Ajax Response
 * @param {*} element Jquery Selector
 */
function populateRadioButtons(result, element) {
    var selector = element.empty();
    var oepns = [];
    var isNotePresent = false;
    var clearNotes = false;
    if (result && result.length > 0) {
        result.forEach(function (object, index, array) {
            if (object.note) {
                isNotePresent = true;
                var radioButtonId = 'radio-' + index;
                selector.append('<div class="group-' + index + '"></div>');
                selector.find('.group-' + index).append(
                    $('<input class="custom-control-input">').prop({
                        type: 'radio',
                        id: radioButtonId,
                        name: 'radio',
                        value: object.oepn
                    })
                ).append(
                    $('<label class="custom-control-label">').prop({
                        for: radioButtonId
                    }).html(object.note)
                );
                oepns.push(object.oepn);
            } else if (oepns.indexOf(object.oepn) === -1) {
                $('input[name="oepn"]').val(object.oepn);
                $('input[name="radio"]').prop('checked', false);
                oepns.push(object.oepn);
            }
            if (index === array.length - 1 && isNotePresent) {
                showNotesModal();
            } else if (oepns.length === 0) {
                clearNotes = true;
            }
        });
    } else {
        clearNotes = true;
    }
    if (clearNotes) {
        $('input[name="oepn"]').val('');
        $('input[name="radio"]').prop('checked', false);
    } else {
        $('input[name="oepn"]').val(oepns.join("|"));
    }
}

/**
 * Function to make ajax call to populate drop down
 * @param {*} selector selector to which value will append
 * @param {*} data Ajax Response
 * @param {*} args Data to pass to request
 */
function renderSearchResult(selector, data, args, params) {
    var $bulbFinderWrapper = $('.js-bulb-refinement-wrapper');
    var $bulbFinderEl = $('.js-bulb-refinement');
    var $refinementBar = $('.js-refinement-bar');

    if ($bulbFinderWrapper.find('.js-bulb-refinement').length == 0) {
        $bulbFinderEl.prependTo($bulbFinderWrapper);
    }
    selector.html(data);

    $refinementBar = $('.js-refinement-bar');
    $refinementBar.empty();

    displayResultsFor(args);
    initVehicleCollapse($bulbFinderEl);
    initKeywordsCollapse();

    if(params.lookupType == 'bulbs') {
        $('.how-to-btn').on('click', function () {
            renderHowToInstallContent(params);
        })
    }

    $bulbFinderEl.appendTo($refinementBar);
    $bulbFinderWrapper.empty();
}

/**
 * Function to init Vehicle Collapse element for mobile device
 * @param {*} $bulbFinderEl jQuery element - Bulb Finder Search
 */
function initVehicleCollapse($bulbFinderEl) {
    var $vehicleCollapse = $('#collapseVehicle');
    var $vehicleCollapseBody = $('.js-vehicle-body');

    if ($vehicleCollapse.length) {
        $vehicleCollapse.on('show.bs.collapse', function () {
            if ($vehicleCollapseBody.find('.js-bulb-refinement').length == 0) {
                $bulbFinderEl.appendTo($vehicleCollapseBody);
                $('.js-modify-vehicle-btn').trigger("click");
            }
        });
    }
    
    if(window.outerWidth > 768 && $('.js-modify-vehicle-refinement').length) {
        $('.js-modify-vehicle-refinement button.btn').trigger('click');
    }
}

/**
 * Function to init Keywords Collapse elements
 */
 function initKeywordsCollapse() {
    var $collapseBtns = $('.js-keywords-collapse-btn');
    var $collapseContent = $('.js-keywords-collapse-content');

    $collapseBtns.on('click', function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).find('.bulb-icon').removeClass('active');
            $(`*[data-forbtn="${$(this).attr('id')}"]`).hide();
            
        } else {
            $collapseBtns.removeClass('active');
            $collapseBtns.find('.bulb-icon').removeClass('active');
            $(this).addClass('active');
            $(this).find('.bulb-icon').addClass('active');
            $collapseContent.hide();
            $(`*[data-forbtn="${$(this).attr('id')}"]`).css('display','flex');
        }
        
        
    });
};

/**
 * Function to get and set content to How to install element
 */
function renderHowToInstallContent(params) {
    var year = $('.js-bulbfinder-selected-year').data('year');
    var make = $('.js-bulbfinder-selected-make').data('make');
    var model = $('.js-bulbfinder-selected-model').data('model');
    var positionName = params.positionName || '';
    var url = $('.how-to-btn').data('url');
    $.ajax({
        url: url,
        method: "GET",
        dataType: "json",
        data: { Year: year, Make: make, Model: model, PositionName: positionName},
    }).done(function (data) {
        var response = JSON.parse(data.response);
        //console.log(data);
        //console.log(response);
        if (!response.Error) {
            var videos = response.Videos;
            var images = response.Images;
            var eti = response.ETI;
            var eti1 = response.ETI1;
            var tools = response.Tools;
            var bts = response.BTS;
            var $mediaWrapper = $('.video.how-to-carousel-slider');
            var $mediaWrapperNav = $('.thumbnail-container.carousel-nav-for-how-to');
            var $toolsWrapper = $('.js-bulb-tools');

            if (!loaded) {
                if(videos) {
                    videos.forEach(function(el) {
                        var videoHtml = 
                        `<div class="video-wrapper"> 
                            <video controls src="${el.Video}" poster="${el.Poster}"></video>
                        </div>`;
                        $mediaWrapper.append(videoHtml);

                        var videoNavHtml = 
                        `<div class="thumbnail image-nav">
                            <img src="${el.Poster}" alt="${el.Title}" alt="image">
                        </div>`;
                        $mediaWrapperNav.append(videoNavHtml);
                    });
                    // Init Slick
                    initHowToSlider(true);
                }

                if (eti == ' -  minutes' || eti == 'not currently available') { eti = null; }
                if (eti) {
                    var etiHtml = `<p class="text">${eti}</p>`;
                    $('.js-bulb-estimation').removeClass('d-none').append(etiHtml);
                }

                if (eti1) {
                    var eti1Html = `<p class="text">${eti1}</p>`;
                    $('.js-bulb-estimation-per-bulb').removeClass('d-none').append(eti1Html);
                }

                if (tools) {
                    tools.forEach(function (el) {
                        var toolHtml = `<p class="text d-flex align-items-center"><img class="tool-icon" src="${el.Image}" alt="tools icon">${el.Name}</p>`;
                        $toolsWrapper.append(toolHtml);
                    })
                    $toolsWrapper.removeClass('d-none');
                    $toolsWrapper.closest('.section-three').removeClass('d-none');
                }

                if (bts) {
                    var btsHtml = `<p class="text">${bts}</p>`;
                    $toolsWrapper.removeClass('d-none').append(btsHtml);
                    $toolsWrapper.closest('.section-three').removeClass('d-none');
                }
                loaded = true;
            }

            $('#bulbVideoBtn').removeClass('d-none');
        } else {
            $('#bulbVideoBtn').addClass('d-none');
        }
        var videos = document.getElementsByTagName('video');
        for (var i=0; i<videos.length; i++) {
            videos[i].addEventListener('ended',myHandler,false);
        }
    })
}

function renderInstallationVideos(dataToggle) {
    var localData = JSON.parse(localStorage.getItem('bulbJsonData'));
    var response = localData[dataToggle];
    var videos = response.Videos;
    var images = response.Images;
    var eti = response.ETI;
    var eti1 = response.ETI1;
    var tools = response.Tools;
    var bts = response.BTS;
    var $mediaWrapper = $('.video-container .video.how-to-carousel-slider');
    var $mediaWrapperNav = $('.video-container .thumbnail-container.carousel-nav-for-how-to');
    var $toolsWrapper = $('.js-bulb-tools');
    if($('.video.how-to-carousel-slider').hasClass('slick-initialized')){
        initHowToSlider(false);
        $('.video.how-to-carousel-slider').empty();
        $('.thumbnail-container.carousel-nav-for-how-to').empty();
    }
    if (!loaded) {
        if(videos) {
            videos.forEach(function(el) {
                var videoHtml = 
                `<div class="video-wrapper"> 
                    <video controls src="${el.Video}" poster="${el.Poster}"></video>
                </div>`;
                $mediaWrapper.append(videoHtml);

                var videoNavHtml = 
                `<div class="thumbnail image-nav">
                    <img src="${el.Poster}" alt="${el.Title}" alt="image" width="57">
                </div>`;
                $mediaWrapperNav.append(videoNavHtml);
            });
            // Init Slick
            initHowToSlider(true);
        }

        if (eti == ' -  minutes' || eti == 'not currently available') { eti = null; }
        if (eti) {
            var etiHtml = '<p class="text">'+eti+'</p>';
            $('.js-bulb-estimation').removeClass('d-none').html(etiHtml);
        }

        if (eti1) {
            var eti1Html = '<p class="text">'+eti1+'</p>';
            $('.js-bulb-estimation-per-bulb').removeClass('d-none').html(eti1Html);
        }

        if (tools) {
            $toolsWrapper.empty();
            tools.forEach(function (el) {
                var toolHtml = '<p class="text d-flex align-items-center"><img class="tool-icon" src="'+el.Image+'" alt="tools icon">'+el.Name+'</p>';
                $toolsWrapper.append(toolHtml);
            })
            $toolsWrapper.removeClass('d-none');
            $toolsWrapper.closest('.section-three').removeClass('d-none');
        }

        if (bts) {
            var btsHtml = '<p class="text">'+bts+'</p>';
            $toolsWrapper.removeClass('d-none').empty().append(btsHtml);
            $toolsWrapper.closest('.section-three').removeClass('d-none');
        }
    loaded = true;
   }
}
$(document).on('click', '.video-modal-trigger', function (e) {
    e.preventDefault();
    loaded = false;
    var dataToggle = $(this).data('toggle');

    const $modal = $('#installPageModal').modal('show');
    renderInstallationVideos(dataToggle);
    setTimeout(function () {
        $('.slick-slider').slick('refresh');
    }, 250);

    $modal.on('hidden.bs.modal', function () {
        var video = $(this).find('.modal-content').find('video');
        video.trigger('pause');
    });
});

/**
 * Function to init Keywords Collapse elements
 * @param {*} isActive - bool value to slick or unslick How to slider
 */
function initHowToSlider(isActive) {
    var howToOpts = {
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        infinite: false,
        asNavFor: '.carousel-nav-for-how-to'
    };
    if (isActive) {
        // Add delay for FF
        if (navigator.userAgent.match(/firefox|fxios/i)) {
            setTimeout(function () {
                $('.how-to-carousel-slider').slick(howToOpts);
            }, 250);
        } else {
            $('.how-to-carousel-slider').slick(howToOpts);
        }

        $('.carousel-nav-for-how-to').slick({
            slidesToShow: 7,
            arrows: false,
            slidesToScroll: 7,
            asNavFor: '.how-to-carousel-slider',
            dots: false,
            centerMode: true,
            infinite: false,
            focusOnSelect: true,
            adaptiveHeight: true,
            responsive: [
            {
            breakpoint: 1024,
            settings: {
                slidesToShow: 6,
                centerMode: false,
                slidesToScroll: 1,
                infinite: false
            }
            },
                {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToScroll: 1,
                    slidesToShow: 5,
                    centerMode: false
                }
            },
                {
                breakpoint: 480,
                settings: {
                    slidesToScroll: 1,
                    slidesToShow: 5,
                    centerMode: false
                }
                }
            ]
        });
    } else {
        $('.carousel-nav-for-how-to.slick-initialized').slick('unslick');
        $('.how-to-carousel-slider.slick-initialized').slick('unslick');
    }
}

function sortPositions(positions) {
    var mappedPositions = new Map();
    var newPositions = new Array();
    positions.map(function (p) {
        mappedPositions.set(p.use_name, p);
    });
    [   "Headlight Bulb Low Beam",
        "Headlight Bulb High Beam",
        "Headlight Bulb High Beam and Low Beam",
        "Fog Light Bulb",
        "Fog Light Bulb Front",
        "Fog Light Bulb Rear",
        "Brake Light Bulb",
        "Brake Light Bulb Inner",
        "Brake Light Bulb Outer",
        "Turn Signal Light Bulb",
        "Turn Signal Light Bulb Front",
        "Turn Signal Light Bulb Rear",
        "Turn Signal Light Bulb Front Inner",
        "Turn Signal Light Bulb Front Outer",
        "Back Up Light Bulb",
        "Back Up Light Bulb Inner",
        "Back Up Light Bulb Outer",
        "Side Marker Light Bulb",
        "Side Marker Light Bulb Front",
        "Side Marker Light Bulb Rear",
        "Tail Light Bulb",
        "Tail Light Bulb Inner",
        "Tail Light Bulb Outer",
        "Tail Light Bulb Upper",
        "Tail Light Bulb Lower",
        "Daytime Running Light Bulb",
        "License Plate Light Bulb",
        "Dome Light Bulb",
        "Center High Mount Stop Light Bulb",
        "Parking Light Bulb",
        "Parking Light Bulb Front",
        "Map Light Bulb",
        "Trunk or Cargo Area Light",
        "Ash Tray Light Bulb",
        "Automatic Transmission Indicator Light Bulb",
        "Check Engine Light Bulb",
        "Cigarette Lighter Light Bulb",
        "Clock Light",
        "Cornering Light Bulb",
        "Courtesy Light Bulb",
        "Cruise Control Indicator Light Bulb",
        "Door Mirror Illumination Light Bulb",
        "Electronic Traction Control Indicator Light Bulb",
        "Engine Compartment Light Bulb",
        "Engine Oil Level Indicator Light Bulb",
        "Floor Console Compartment Light Bulb",
        "Glove Box Light Bulb",
        "High Beam Indicator Light Bulb",
        "Hot Engine Indicator Light Bulb",
        "HVAC Temperature Control Bulb",
        "Ignition Light",
        "Ignition Light Bulb",
        "Instrument Panel Light Bulb",
        "Interior Door Light Bulb",
        "Parking Brake Indicator Light Bulb",
        "Radio Dial Light",
        "Radio Display Light Bulb",
        "Reading Light Bulb",
        "Reading Light Bulb Rear",
        "Roof Marker Light Bulb",
        "Spotlight Bulb",
        "Spotlight Bulb Front",
        "Spotlight Bulb Rear",
        "Stepwell Light Bulb",
        "Trunk Light Bulb",
        "Turn Signal Indicator Light Bulb",
        "Vanity Mirror Light Bulb",
        "Vehicle-Mounted Work Light"
    ].forEach(function (u) {
        if (mappedPositions.has(u)) {
            newPositions.push(mappedPositions.get(u));
            mappedPositions.delete(u);
        }
    });
    return newPositions.concat(Array.from(mappedPositions.values()));
}

/**
 * Function to make ajax call to populate drop down
 * @param {*} args Data to pass to request
 * @param {*} selectorType  selectorType
 * @param {*} selector selector to which values will append
 * @param {*} url URL
 * @param {*} requestType Ajax requestType get/post
 * @returns {*} response Ajax call response
 */
function ajaxCall(args, selectorType, selector, url) {
    var ajaxPromise = new Promise(function (resolve) {
        var params = args;
        if (( selectorType === 'dropdown' || isAjaxRequestForSearchResultsPage(selectorType) ) && $(selector).attr('id') !== 'bulbFinderLocations') {
            selector.parent().spinner().start();
        }
        jQuery.ajax(url,{type: 'get',data: params,success: function (data, status) {
            if (status === 'success') {
                var result = data.response;
                if("positions"===args.lookupType){
                    result=sortPositions(result);
                }
                if (result && result.length > 0 && selectorType === 'dropdown') {
                    populateDropDowns(result, selector);
                } else if (result && result.length > 0 && selectorType === 'input') {
                    selector.val(result[0].id);
                } else if (data !== undefined && isAjaxRequestForSearchResultsPage(selectorType)) {
                    renderSearchResult(selector, data, args, params);
                } else if (data !== undefined && selectorType === 'radio') {
                    var sortedResult = [];
                    var isDuplicate = false;
                    result.forEach(function (object, index) {
                        if (index === 0) {
                            sortedResult.push(object);
                        } else {
                            sortedResult.forEach(function (sobject) {
                                if (sobject.note === object.note) {
                                    //append oepn
                                    sobject.oepn = sobject.oepn + " | " + object.oepn;
                                    isDuplicate = true;
                                } else {
                                    isDuplicate = false;
                                }
                            });

                            if (!isDuplicate) {
                                sortedResult.push(object);
                            }
                        }
                    });
                    populateRadioButtons(sortedResult, selector);
                } else if (selectorType === 'none') {
                    selector.parent().spinner().stop();
                }
                if (selectorType === 'dropdown') {
                    selector.parent().spinner().stop();
                }
                if (data.showCaptcha) {
                    $('#showCaptcha').val(data.showCaptcha);
                }
            }
            if ($(selector).attr('id') !== 'car_id') {
                selector.parent().spinner().stop();
            }
            $("#bulb-by-bulb .bulbfinder-disclaimer.bulbfinder-error").removeClass("bulbfinder-error").html("");
            resolve(status);
        },
        error: function (err) {
            console.error(err.responseText);
            selector.parent().spinner().stop();
            try{
                if(err.responseJSON.userVisible){
                    $("#bulb-by-bulb .bulbfinder-disclaimer").addClass("bulbfinder-error").html(err.responseJSON.errorMessage);
                }
            }catch(ex){}
            resolve(err.status);
        }});
    });

    return ajaxPromise;
}

function isAjaxRequestForSearchResultsPage(selectorType) {
    return selectorType === 'div';
}

/**
 * Reset the values on change of car_id
 */
function resetValuesOnCarIdChange(thisForm) {
    var $this = thisForm;

    $this.find('input[name="carId"]').val('');
    $this.find('input[name="oepn"]').val('');
    
    var bulbFinderPosition = $('select[name="bulbFinderPositions"]').empty();
    var $bulbFinderNotes = $('select[name="bulbFinderNotes"]').empty();
    bulbFinderPosition.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
    $bulbFinderNotes.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
    $bulbFinderNotes.attr('disabled', true);
    bulbFinderPosition.attr('disabled', true);
    $('.select-bulb-notes').addClass('d-none');

    $('#findbulbRedirect').attr('disabled', true);
    $('#findvideoRedirect').attr('disabled', true);
    $('#findbulb').attr('disabled', true);
    loaded = false;
}
/**
 *This will populate notes and position dropdown
 *@param {*} url  AJax call URL
 */
function getPositionsUsingCarId(url, thisForm) {
    var $this = thisForm;
    var captchaPromise = getNewToken('positions');
    captchaPromise.then(
        function (value) {
            if (value === 'success') {
                var positionsReq = createRequest('positions', $this);
                var ajaxPromise = ajaxCall(positionsReq, 'dropdown', $('select[name="bulbFinderPositions"]'), url);
                ajaxPromise.then(function () {
                    $('select[name="bulbFinderPositions"]').parent().spinner().stop();
                });
            }
        }
    );
}

/**
 * Function to get bulbs
 * @param {*} url URL
 * @param {*} searchType bypartNumber/bulbs
 */
function getBulbs(url, searchType, thisForm) {
    var $this = thisForm;
    var captchaPromise = getNewToken(searchType);
    captchaPromise.then(
        function (value) {
            if (value === 'success') {
                var bulbReq = createRequest(searchType, $this);
                var ajaxPromise = ajaxCall(bulbReq, 'div', $('div.search-results'), url);
                ajaxPromise.then(function () {
                    callSaveDataController($this)
                });
            }
        }
    );
}

function callSaveDataController($this) {
    var saveData = createRequest('pagedata', $this);
    var captchaPromise = getNewToken('pagedata');
    captchaPromise.then(
        function (value) {
            if (value === 'success') {
                var $this = $('#findbulb').closest('.bulb-finder-form');
                var pageurl = $('#findbulb').data('pageurl');
                var saveData = createRequest('pagedata', $this);
                saveData.resultCount = $('div.search-results .grid-tile-wrapper').length

                jQuery.ajax(pageurl,{ type: 'get', data: saveData,
                    success: function () {
                    },
                    error: function (err) {
                        console.log(`Corrupt attempt was made to store vehicle data`)
                    }});
            }
        }
    );
}

/**
 * Function to get the carId
 */
function getCarId(thisForm) {
    var $this = thisForm;
    var selector = $this.find('select[name="bulbFinderPositions"]');
    resetValuesOnCarIdChange($this);
    var captchaPromise = getNewToken('car_id');
    captchaPromise.then(
        function (value) {
            if (value === 'success') {
                var carReq = createRequest('car_id', $this);
                var url = $this.find('select[name="bulbFinderMake"]').data('url');
                var ajaxPromise = ajaxCall(carReq, 'input', $this.find('input[name="carId"]'), url);
                ajaxPromise.then(function (resolve) {
                    if (resolve === 'success') {
                        url = $this.find('select[name="bulbFinderMake"]').data('url');
                        getPositionsUsingCarId(url, $this);
                        selector.removeAttr('disabled');
                    }
                });
            }
        }
    );
}

/**
 * Function to get Notes based on use_id and car_id
 */
function getNotes(thisForm) { 
    var $this = thisForm;
    var captchaPromise = getNewToken('notes');
    var notesPromise; 
    var $bulbFinderNotes = $('select[name="bulbFinderNotes"]');
    captchaPromise.then(
        function (value) {
            if (value === 'success') {
                var notesReq = createRequest('notes', $this);
                var url = $this.find('select[name="bulbFinderMake"]').data('url');
                notesPromise = ajaxCall(notesReq, 'dropdown', $bulbFinderNotes, url);
                notesPromise.then(function (resolve) {
                    if (resolve === 'success') {
                        if ($bulbFinderNotes.children('option').length > 1) {
                            $bulbFinderNotes.removeAttr('disabled');
                            $('.select-bulb-notes').removeClass('d-none');
                            $this.find('#findbulbRedirect').attr('disabled', true);
                            $('#findvideoRedirect').attr('disabled', true);
                            $('#findbulb').attr('disabled', true);
                        } else {
                            $bulbFinderNotes.attr('disabled', true);
                            $('.select-bulb-notes').addClass('d-none');
                            $('#findbulbRedirect').attr('disabled', false);
                            $('#findvideoRedirect').attr('disabled', false);
                            $('#findbulb').attr('disabled', false);
                        }
                    }
                });
            }
        }
    );
}

function displayResultsFor(args) {
    if($('.bulb-tabs.bulb-search').hasClass('active')) {
        //Display selected bulb filter values
        var $bulbFinderResult = $('.bulb-finder-results--keywords');
        var hasAllSelected = true;
        var queryString = QueryStringToJSON($('.page').data('querystring'));
        var oepn = $('input[name="oepn"]').val();

        $('[data-select="bulbFinderOEPN"]').text(oepn);
        // if(queryString['oepn']) {
        //     $('[data-select="bulbFinderOEPN"]').text(queryString['oepn']);
        // }

        //Change browser URL
        var oldURL = window.location.href;
        var newURL = '';
        var params = new URLSearchParams(window.location.search);

        newURL = oldURL.replace(window.location.search, '');
        params.set('oepn', oepn);
        newURL = newURL +'?'+params.toString();
        
        window.history.pushState({}, '', newURL);
        window.history.replaceState({}, '', newURL);

        $.each($bulbFinderResult.find('.keywords li'), function(idx, el){
            el = $(el);
            if (el.data('select') == 'bulbFinderNote') {
                
                if ($('input[name="notes"]').val().length > 0) {
                    $('li[data-select=bulbFinderNote]').text($('input[name="notes"]').val());
                } else if (queryString['notes']) {
                    $('input[name="notes"]').val(queryString['notes']);
                    $('li[data-select=bulbFinderNote]').text(queryString['notes']);
                }
                return;
            } else {
                var selIdTxt = $('#'+el.data('select')+' option:selected').text();
                var selVal = $('#'+el.data('select')).val();

                if(selVal != null && selVal.trim() != '') {
                    el.text(selIdTxt);
                } else {
                    hasAllSelected = false;
                    $('.bulb-finder-results').addClass('d-none');
                }
            }
        });

        if(hasAllSelected) {
            $('.bulb-finder-results').removeClass('d-none');

            $('#bulbInstallationVideoModal').on('hidden.bs.modal', function (e) {
                var $this = $(this);
                $this.find('.modal-body').empty();
            }).on('shown.bs.modal', function(){
                var $this = $(this);
                var urlVideo = $this.data('install-video');
            
                $this.find('.modal-body').append($([
                '<div class="embed-responsive embed-responsive-16by9">',
                '   <iframe class="embed-responsive-item" src="'+urlVideo+'" allowfullscreen></iframe>',
                '</div>' 
                ].join('')));
            });
        }

        if(window.outerWidth < 1024 && typeof args !== 'undefined') {
            var $tabs = $('.bulb-finder-header > .container-tabs');
            $tabs.removeClass('show');
            $tabs.find('.bulb-finder-row .form-group.custom-select-group').addClass('d-none');
            $('.modify-vehicle').show();

        }
    } else {
        $('.bulb-finder-results').addClass('d-none');
    }
}
function QueryStringToJSON(queryString) {            
    var pairs = queryString.slice(1).split('&');
    
    var result = {};
    pairs.forEach(function(pair) {
        pair = pair.split('=');
        result[pair[0]] = decodeURIComponent(pair[1] || '');
    });

    return JSON.parse(JSON.stringify(result));
}
// On year dropdown change functions
$('select[name="bulbFinderYear"]').on('change', function (e) {
    e.preventDefault();
    var $this = $(this).closest('.bulb-finder-form');
    var bulbFinderMake = $('select[name="bulbFinderMake"]').empty();
    var bulbFinderModel = $('select[name="bulbFinderModel"]').empty();
    bulbFinderMake.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
    bulbFinderModel.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
    $('select[name="bulbFinderModel"]').prop( "disabled", true );
    $('select[name="bulbFinderPositions"]').prop( "disabled", true );
    resetValuesOnCarIdChange($this);
    if(!$this.find('select[name="bulbFinderYear"]').val()){
        return;
    }
    var captchaPromise = getNewToken('makes');
    captchaPromise.then(
        function (value) {
            if (value === 'success') {
                var makesReq = createRequest('makes', $this);
                var url = $this.find('select[name="bulbFinderMake"]').data('url');
                $('.select-bulb-make').removeClass('d-none');
                ajaxCall(makesReq, 'dropdown', $('select[name="bulbFinderMake"]'), url);
                bulbFinderMake.removeAttr('disabled');
                if ($('.installPageHeader').length){
                    $('#bulbFinderYear').val($('#yearSelector').val());
                    $('#select2-bulbFinderYear-container').html($('#select2-yearSelector-container').html());
                }
            }
        }
    );
});
// On Makes dropdown change functions
$('select[name="bulbFinderMake"]').on('change', function (e) {
    e.preventDefault();
    var $this = $(this).closest('.bulb-finder-form');
    var bulbFinderModel = $('select[name="bulbFinderModel"]').empty();
    bulbFinderModel.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
    $('select[name="bulbFinderPositions"]').prop( "disabled", true );
    resetValuesOnCarIdChange($this);
    var captchaPromise = getNewToken('models');
    captchaPromise.then(
        function (value) {
            if (value === 'success') {
                var modelsReq = createRequest('models', $this);
                var url = $this.find('select[name="bulbFinderMake"]').data('url');
                $('.select-bulb-model').removeClass('d-none');
                ajaxCall(modelsReq, 'dropdown', $('select[name="bulbFinderModel"]'), url);
                $('select[name="bulbFinderModel"]').removeAttr('disabled');
                if ($('.installPageHeader').length){
                    $('#bulbFinderMake').val($('#makeSelector').val());
                    $('#select2-bulbFinderMake-container').html($('#select2-makeSelector-container').html());
                }
            }
        }
    );
});
// On Model dropdown change functions
$('select[name="bulbFinderModel"]').on('change', function (e) {
    e.preventDefault();
    var $this = $(this).closest('.bulb-finder-form');
    e.preventDefault();
    $('.select-bulb-position').removeClass('d-none');
    resetValuesOnCarIdChange($this);
    getCarId($this);
    if ($(this).hasClass('install-video-model-option')) {
        $('#findvideoRedirect').attr('disabled', false);
        $('#bulbFinderModel').val($('#modelSelector').val());
        $('select[name="bulbFinderPositions"]').removeAttr('disabled');
        setTimeout(function(){
            $('#select2-bulbFinderModel-container').html($('#select2-modelSelector-container').html());
        }, 2000);
        
    } 
});
// On BulbPostions  change functions
$('select[name="bulbFinderPositions"]').on('change', function (e) {
    e.preventDefault();
    var $this = $(this).closest('.bulb-finder-form');
    var bulbFinderNotes = $('select[name="bulbFinderNotes"]').empty();
    $('.saveVehicleOnAccount').prop("disabled", false)
    bulbFinderNotes.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
    getNotes($this);
});
// On Notes dropdown change functions
$('select[name="bulbFinderNotes"]').on('change', function (e) {
    e.preventDefault();
    var $this = $(this).closest('.bulb-finder-form');
    var oepn = $this.find('select[name="bulbFinderNotes"]').val();
    var notes = $this.find('select[name="bulbFinderNotes"] option:selected').text();
    $('input[name="oepn"]').val(oepn);
    $('input[name="notes"]').val(notes);
    $this.find('#findbulbRedirect').attr('disabled', false);
    $this.find('#findvideoRedirect').attr('disabled', false);
    $this.find('#findbulb').attr('disabled', false);
});
// On BulbPostions  change functions
$('select[name="bulbFinderLocations"]').on('change', function (e) {
    e.preventDefault();
    var positionName = $(this).val();
    $('.select-bulb-position').removeClass('d-none');
    populateBulbPositionsBasedOnLocations(positionName);
});
// Click function for bulbs search by part Number
$('#findbulbByPartNumber').on('click', function (e) {
    e.preventDefault();
    var $this = $(this).closest('.bulb-finder-form');
    var url = $(this).data('url');
    getBulbs(url, 'bulbsByPartNumber', $this);
});
// click function on Reset Link
$('[id=resetLink]').on('click', function (e) {
    e.preventDefault();
    var url = $(this).attr('href');
    $.get(url, function (data, status) {
        if (status === 'success') {
            $('select[name="bulbFinderYear"]').val(null).trigger('change');
            var bulbFinderMake = $('select[name="bulbFinderMake"]').empty();
            var bulbFinderModel = $('select[name="bulbFinderModel"]').empty();
            bulbFinderMake.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
            bulbFinderMake.attr('disabled', true);
            bulbFinderModel.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
            bulbFinderModel.attr('disabled', true);
            resetValuesOnCarIdChange();
            $('input[name="carId"]').val('');
            $('input[name="oepn"]').val('');
            $('input[name="radio"]').prop('checked', false);
            loaded = false;

            $('#findbulbRedirect').attr('disabled', true);
            $('#findvideoRedirect').attr('disabled', true);
            $('#findbulb').attr('disabled', true);
            $('.select-bulb-make').addClass('d-none');
            $('.select-bulb-model').addClass('d-none');
            $('.select-bulb-position').addClass('d-none');
            
            if ($('#findbulb').length > 0) {
                $('#findbulbRedirect').removeClass('d-none');
                $('#findvideoRedirect').removeClass('d-none');
                $('#findbulb').addClass('d-none');
            }

            if (localStorage.getItem('bulbFinderYear') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('bulbFinderMake') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('bulbFinderModel') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('bulbFinderLocations') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('bulbFinderPositions') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('backURL') !== null) {
                localStorage.removeItem('backURL');
            }

        }
    });
});
// Find bulb click function
$('#findbulb').on('click', function (e) {
    $(this).spinner().start();
    e.preventDefault();
    var $this = $(this).closest('.bulb-finder-form');
    var url = $(this).data('url');
    getBulbs(url, 'bulbs', $this);
    localStorage.setItem('findbulb', 1);
});
// To Redirect URL
$('#findbulbRedirect').on('click', function (e) {
    $(this).spinner().start();
    e.preventDefault();
    var $this = $(this).closest('.bulb-finder-form');
    var url = $(this).data('url');
    var carId = $this.find('input[name="carId"]').val();
    var radioButtonVal = $('input[name="radio"]:checked').val();
    var oepn = radioButtonVal !== undefined ? radioButtonVal : $this.find('input[name="oepn"]').val();
    var notes = $('input[name="notes"]').val().length > 0 ? $this.find('input[name="notes"]').val() : '';
    var index = 0;
    if (carId) {
        url = url + (index > 0 ? '&' : '?') + 'carId=' + carId;
        index = 1;
    }
    if (oepn) {
        url = url + (index > 0 ? '&' : '?') + 'oepn=' + oepn;
        index = 1;
    }
    if (notes) {
        url = url + (index > 0 ? '&' : '?') + 'notes=' + notes;
        index = 1;
    }
    localStorage.setItem('backURL', url);
    window.location.href = url;
    $.spinner().stop();
});

function buildSingleVideoBlock(counter, response, bulbPos) {
    var bulbPos = JSON.parse(bulbPos);
    var bulbPositionName = 'Bulb Position';
    $.each(bulbPos, function(index, item){
        if(response.LookupURL.indexOf(item) > -1){
            bulbPositionName = index;
            return false;
        }
    });
   
    var eti = response.ETI;
    if (eti == ' -  minutes' || eti == 'not currently available') { eti = null; }
    var etiHtml = '';
    if (eti) {
        etiHtml = '<p class="text">'+eti+'</p>';
    }
    var tools = response.Tools;
    var toolsHtml = '';
    if (tools) {
        tools.forEach(function (el) {
            toolsHtml += '<p class="text large-text d-flex align-items-center"><img class="tool-icon" src="'+el.Image+'" alt="tools icon">'+el.Name+'</p>'
        })
    }
    var poster = 'Poster' in response.Videos[0] ? response.Videos[0].Poster : '';

    var htmlString = '<div class="card-banner-tile card-two-column video-tile">'
        +'<div class="card-heading mb-2" style="color:rgb(254, 80, 0);">'+bulbPositionName+'</div>'
        +'<div class="video-banner col-12 col-md-8">'
            +'<a href="" class="video-modal-trigger" title="'+response.Title+'" data-toggle="'+counter+'" data-target="" data-video="" data-video-id="'+bulbPositionName+'">'
                +'<div class="banner-image">'
                    +'<picture>'
                        +'<!-- Mobile image -->'
                        +'<source media="(max-width: 899px)" srcset="'
                        +poster
                        +'">'
                        +'<img class=" lazyloaded" src="'
                        +poster
                        +'" data-src="'
                        +poster
                            +'alt="'
                            +response.Title
                            +'">'
                    +'</picture>'
                +'</div>'
            +'</a>'
        +'</div>'

        +'<div class="card-caption">'
            +'<div class="card-copy mb-2">'
                    +etiHtml
                    +toolsHtml
            +'</div>'
        +'</div>'
        +'<div class="bulb-button-holder"><button class="affirm btn btn-primary add-vehicle-btn">View Bulb Options</button></div>'
    +'</div>';
 
    return htmlString;
}

function buildVideoBlock(data) {
    var hasError = false;
    var response = JSON.parse(data);
    var bulbPos = $('.bulb-position-data').html();
    for (var i in response) { 
        if(i === 'Error') {
            hasError = true;
        } else if (response[i] != null) {
            $('.card-tiles.video-modules').append(buildSingleVideoBlock(i, response[i], bulbPos));
        }
    };
    if (hasError) {
        $('.no-car-data').show();
    } else {
        $('.card-banner.type-card.video-results').show();

        $('.card-banner.type-card.video-results').each(function(){  
            var highestBox = 0;
    
            $(this).find('.card-caption').each(function(){
                if($(this).height() > highestBox){  
                    highestBox = $(this).height();  
                }
            });
            $(this).find('.card-caption').height(highestBox);
        });
    }
}

// Get Instal Video Data
$('#findvideoRedirect').on('click', function (e) {
    e.preventDefault();
    $.spinner().start();
    var $this = $(this).closest('.bulb-finder-form');
    var url = $(this).data('url');
    var year = $this.find('select[name="bulbFinderYear"]').val(); 
    var make = $this.find('select[name="bulbFinderMake"] option:selected').text();
    var model = $this.find('select[name="bulbFinderModel"] option:selected').text();
    $('.card-tiles.video-modules').empty();
    localStorage.removeItem('bulbJsonData');
    $('.no-car-data, .card-banner.type-card.video-results').hide();
    

   $.ajax({
        url: url,
        method: "GET",
        dataType: "json",
        data: { Year: year, Make: make, Model: model, Procedure: 'lights'},
        success: function (data) {
            if (!data.response) {
                console.log('failed');
            } else {
                $('.card-tiles.video-modules').append(buildVideoBlock(data.response));
                localStorage.setItem('bulbJsonData', data.response); 
            }
        }
    });
    $.spinner().stop();
});

// To Redirect URL
$('#findBulbByPartNumberRedirect').on('click', function (e) {
    e.preventDefault();
    var url = $(this).data('url');
    var partNumber = $('#partNumber').val();
    if (partNumber) {
        url = url + '?partNumber=' + partNumber;
    }
    window.location.href = url;
});

$('body').on('click', 'a.header-results-link', function () {
    window.history.go(-1);
});

$('.bulb-position-modal').on('click', function () {
    var content = $('#requestPosition').html();
    var htmlString = '<!-- Modal -->'
        + '<div class="modal show" id="request-position" aria-modal="true" role="dialog" style="display: block;">'
        + '<div class="modal-dialog">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + '<div class="modal-title"></div>'
        + '<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
        + '</div>'
        + '<div class="modal-body">' + content + '</div>'
        + '</div>'
        + '</div>'
        + '</div>'
        + '</div>';

    if ($('#request-position').length === 0) {
        $('body').append(htmlString);
    }
    $('#request-position').modal('show');
});

// Trigger event in case of Bulb Show
var cgid = $('#cgid').val();
if (window.location.href.indexOf('BulbFinder-Show') !== -1) {
    if (window.location.href.indexOf('partNumber') !== -1) {
        $('#findbulbByPartNumber').trigger('click');
        $('a.partnumber-search').trigger('click');
    } else {
        $('#findbulb').trigger('click');
    }
} else if (cgid && cgid !== 'null') {
    applyFilterOnStoredPartNumbers();
}

$('a.bulb-tabs[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    var $this = $(e.target);

    if($this.hasClass('partnumber-search')) {
        $('.bulb-finder-results').addClass('d-none');
    } else {
        displayResultsFor();
    }
});

$('body').on('click', '.modify-vehicle button.btn', function(e, opt){
    var $this = $(this);
    var $tabs = $('.bulb-finder-header > .container-tabs');
    e.preventDefault();

    $tabs.addClass('show');
    $this.closest('.modify-vehicle').hide();

    if(typeof opt === 'undefined') {
        $tabs.find('.bulb-finder-row .form-group.custom-select-group').not('.select-bulb-notes').removeClass('d-none');

        if ($('.select-bulb-notes').find('option').length > 1) {
            $tabs.find('.bulb-finder-row .form-group.custom-select-group.select-bulb-notes').removeClass('d-none')
        }
    }

});

if($('#bulb-by-bulb').length > 0) {
    $('body').on('change', 'select[name="bulbFinderYear"]', function () {
        localStorage.setItem('bulbFinderYear', $(this).val());
    });

    $('body').on('change', 'select[name="bulbFinderMake"]', function () {
        localStorage.setItem('bulbFinderMake', $(this).val());
    });

    $('body').on('change', 'select[name="bulbFinderModel"]', function () {
        localStorage.setItem('bulbFinderModel', $(this).val());
    });

    $('body').on('change', '#bulbFinderLocations', function () {
        localStorage.setItem('bulbFinderLocations', $(this).val());
    });

    $('body').on('change', 'select[name="bulbFinderPositions"]', function () {
        localStorage.setItem('bulbFinderPositions', $(this).val());
    });
}

var bulbFinderSelectList = ['select[name="bulbFinderYear"]', 'select[name="bulbFinderMake"]', 'select[name="bulbFinderModel"]', '#bulbFinderLocations', 'select[name="bulbFinderPositions"]'];
var bulbFinderArr = [];
$(bulbFinderSelectList).each(function(idx, attr){
    if ($(attr).children('option:first-child').is(':selected')) {
        bulbFinderArr.push('true');
    } else {
        bulbFinderArr.push('false');
    }
});

var bulbFinderSelectFilled = bulbFinderArr.every(function(val, idx, attr) {
    var isBulbFinderSelectFilled;
    if (val === 'true' && (idx === 0 || val === attr[idx - 1])) {
        isBulbFinderSelectFilled = true;
    } else {
        isBulbFinderSelectFilled = false;
    }

    return isBulbFinderSelectFilled;
});

if (bulbFinderSelectFilled) {
    $('.modify-vehicle button.btn').trigger('click', {init:true});
}

$(document).ready(function() {
    if ($('#modelSelector') && $('#modelSelector').val() != 'Please Select') {
        $('#findvideoRedirect').attr('disabled', false);
    }
    $(window).on('resize', function () {
        var win = $(this);
        
        if (win.width() >= 1024) {
            $('.bulb-finder-header > .container-tabs').removeClass('show');
        } else {
            $('.modify-vehicle button.btn').trigger('click');
        }

        if (win.width() >= 768 && $('.js-modify-vehicle-refinement').length) {
            $('.js-bulb-refinement').appendTo('.js-refinement-bar');
        }
    });

    if(window.outerWidth < 1024 && $('.js-modify-vehicle-widget').length) {
        $('.js-modify-vehicle-widget button.btn').trigger('click');
    }

    $('.js-select-searchbox').select2();
})

$('body').on('click', '.selectVehicle', function(e) {
    e.preventDefault();
    var vsData = $(this).data('vehicleiddata');
    var vsQString = 'year=' + vsData.yearID;
        vsQString += '&make=' + vsData.makeID;
        vsQString += '&model=' + vsData.modelID;
        vsQString += '&notes=' + vsData.notesID;
    
    localStorage.setItem("bulbFinderTrigger",1);
    
    var url = $(this).data('urltarget') + '?' + vsQString;
    window.location.href = url;
})

$('body').on('click', '.deleteVechile', function(e) {
    e.preventDefault();
    var vehicleiddata = $(this).data('vehicleiddata');

    if(vehicleiddata) {
        var vehicleid = (vehicleiddata.yearID || '') + (vehicleiddata.makeID || '') + (vehicleiddata.modelID || '') + (vehicleiddata.notesID || '');
    }

    var currJSON = {
        vehicleid: vehicleid
    };
    var url = $(this).data('action');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: currJSON,
        success: function (data) {
            if (!data.success) {
                console.log('failed');
            } else {
                console.log('success');
                $('.successFullyDeleted').removeClass('d-none');
                setTimeout(function(){
                    $('.successFullyDeleted').addClass('d-none');
                }, 2000);

                var getVehicleURL = $('.getVehicles-container').data('urltarget');

                $.ajax({
                    type: 'get',
                    url: getVehicleURL,
                    success: function (result) {
                        $('.getVehicles-container').html(result);
                    }
                });
            }
        }
    });

})

setTimeout(function() { 
    if (localStorage.getItem("bulbFinderTrigger") !== null && $('.bulb-finder-header').length > 0) {
        $('select[name=bulbFinderModel]').trigger('change');
        localStorage.removeItem("bulbFinderTrigger");
    }
}, 1000);


$('body').on('click','#bulbSaveVehicleBtn',function() {
    var year = $('select[name="bulbFinderYear"]').val() != '' ? $('select[name="bulbFinderYear"]').val() : '';
    var make = $('#bulbFinderMake option:selected').text().trim() != 'Please Select' ? $('#bulbFinderMake option:selected').text() : '';
    var model = $('#bulbFinderModel option:selected').text().trim() != 'Please Select' ? $('#bulbFinderModel option:selected').text() : '';
    var notes = $('#bulbFinderNotes option:selected').text().trim() != 'Please Select' ? $('#bulbFinderNotes option:selected').text() : '';

    var makeID = $('select[name="bulbFinderMake"]').val();
    var modelID = $('select[name="bulbFinderModel"]').val();
    var notesID = $('select[name="bulbFinderNotes"]').val();
    var saveData =  year + ' ' + make + ' ' + model;
    $('.vehicle-container .bold').html(saveData);
    $('.vehicle-container .normal').html(notes);

    $('.vehicle-model-container .bold').html(saveData);
    $('.vehicle-model-container .normal').html(notes);

    var currJSON = {
        "year": year,
        "make": makeID,
        "model": modelID,
        "makeName": make.trim(),
        "modelName": model.trim()
    };

    if(notesID && notesID != 'Please Select') {
        currJSON.notes = notesID;
        notes = notes.replace(/\(/g, '').replace(/\)/g, '').replace(/w\//g, 'With ');
        currJSON.notesName = notes.trim();
    }

    $('.vehicle-model-container .save').attr('data-id', JSON.stringify(currJSON));
});

$('body').on('submit','.save-vehicle-container .bulb-finder-login',function(e) {
    var form = $(this);
    e.preventDefault();
    var url = form.attr('action');
    var dataAction = form.data('action');
    form.spinner().start();
    var vehicleData = $('.vehicle-model-container .save').data('id');
    var vehicleDataString = '';
    for (var key in vehicleData) {
        if (vehicleData.hasOwnProperty(key)) {
            vehicleDataString += key + '=' + vehicleData[key]+'&';
        }
    }
    var currFormData = form.serialize();
        currFormData =vehicleDataString + currFormData;

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: currFormData,
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                $('.invalid-login').removeClass('d-none');
            } else {
                $('.invalid-login').addClass('d-none');
                $('.save-vehicle-container .login-container').removeClass('active');
                $('.saved-vehicle-main-container ').addClass('active');
                $('.current-vehicle-container').hide();
                $.ajax({
                    type: 'get',
                    url: dataAction,
                    success: function (result) {
                        $('.getVehicles-container').html(result);
                    }
                });
            }
        },
        error: function (data) {
            $('form.bulb-finder-login').trigger('login:error', data);
            form.spinner().stop(); 
        }
    });
    return false;
});

$('body').on('click','.save-vehicle-dashboard-container .close-vehicle-btn-account',function() {
    $('.save-vehicle-dashboard-container').removeClass('active');
    $('save-vehicle-dashboard-content .after').removeClass('active');
    $('.add-vehicle-btn').removeClass('active');
    $('.modal-background').hide();
});

$('body').on('click','.add-vehicle-btn',function() {
    var $this = $(this);
    $('.save-vehicle-dashboard-container').toggleClass('active');
    $('save-vehicle-dashboard-content .after').toggleClass('active');
    $('.add-vehicle-btn').toggleClass('active');
    var vehicleiddata = $(this).data('vehicleiddata');
    var action = $(this).data('action');
    if ($('.installPageHeader').length == 0){
        $('#resetLink').trigger('click');
    }
    if($('.video-results').length > 0) {
        $('.modal-background').show();
    }
    $('.saveVehicleOnAccount').prop('disabled','true');
    if(action) {
        $('.saveVehicleOnAccount').text(action + ' Vehicle');
        if(action == 'save') {
            $('.saveVehicleOnAccount').attr('data-action', action);
        } else {
            $('.saveVehicleOnAccount').attr('data-action', action);
        }
    }
    if(vehicleiddata) {
        var vehicleid = (vehicleiddata.yearID || '') + (vehicleiddata.makeID || '') + (vehicleiddata.modelID || '') + (vehicleiddata.notesID || '');
        $('.saveVehicleOnAccount').attr('data-vehicleid', vehicleid);  
    }

});

$('body').on('click','.vehicle-model-container .save',function() {
    var currJSON = $(this).data('id');
    var url = $(this).data('targeturl');
    var getVehicleURL = $(this).data('getvehicle');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: currJSON,
        success: function (data) {
            if (!data.success) {
                console.log('failed');
                $('.notSaved').removeClass('d-none');
                setTimeout(function(){
                    $('.notSaved').addClass('d-none');
                }, 2000);
            } else {
                $('.save-vehicle-btn, .bulb-icon-save-vehicle').removeClass('active');
                $('.save-vehicle-main-container').hide();
                $('.save-vehicle-status-modal').addClass('active');
                setTimeout(function(){
                    $('.save-vehicle-status-modal').removeClass('active');
                }, 2000); 

                $.ajax({
                    type: 'get',
                    url: getVehicleURL,
                    success: function (result) {
                        $('.getVehicles-container').html(result);
                    }
                });

                $('.current-vehicle-container').hide();
            }
        }
    });
})

$('body').on('click','.saveVehicleOnAccount',function(e) {
    e.preventDefault();
    var year = $('select[name="bulbFinderYear"]').val() != '' ? $('select[name="bulbFinderYear"]').val() : '';
    var make = $('#bulbFinderMake option:selected').text().trim() != 'Please Select' ? $('#bulbFinderMake option:selected').text() : '';
    var model = $('#bulbFinderModel option:selected').text().trim() != 'Please Select' ? $('#bulbFinderModel option:selected').text() : '';
    var notes = $('#bulbFinderNotes option:selected').text().trim() != 'Please Select' ? $('#bulbFinderNotes option:selected').text() : '';

    var makeID = $('select[name="bulbFinderMake"]').val();
    var modelID = $('select[name="bulbFinderModel"]').val();
    var notesID = $('select[name="bulbFinderNotes"]').val();
    var saveData =  year + ' ' + make + ' ' + model;
    $('.vehicle-container .bold').html(saveData);
    $('.vehicle-container .normal').html(notes);

    $('.vehicle-model-container .bold').html(saveData);
    $('.vehicle-model-container .normal').html(notes);

    var action = $(this).data('action');

    var vehicleid = null
    if($(this).data('vehicleid')) {
        vehicleid = $(this).data('vehicleid');
    }

    var currJSON = {
        "year": year,
        "make": makeID,
        "model": modelID,
        "makeName": make.trim(),
        "modelName": model.trim(),
        "action": action,
        "vehicleid": vehicleid
    };

    if(notesID && notesID != 'Please Select') {
        currJSON.notes = notesID;
        notes = notes.replace(/\(/g, '').replace(/\)/g, '').replace(/w\//g, 'With ');
        currJSON.notesName = notes.trim();
    }

    var url = $(this).data('targeturl');

    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: currJSON,
        success: function (data) {
            if (!data.success) {
                console.log('failed');
                $('.notSaved').removeClass('d-none');
                setTimeout(function(){
                    $('.notSaved').addClass('d-none');
                    $('.close-vehicle-btn-account').trigger('click');
                }, 2000);
            } else {
                console.log('success');
                $('.successFullyAdded').removeClass('d-none');
                setTimeout(function(){
                    $('.successFullyAdded').addClass('d-none');
                }, 2000);
                
                var getVehicleURL = $('.getVehicles-container').data('urltarget');

                $.ajax({
                    type: 'get',
                    url: getVehicleURL,
                    success: function (result) {
                        $('.getVehicles-container').html(result);
                        $('.close-vehicle-btn-account').trigger('click');
                        $('#resetLink').trigger('click');
                    }
                });

            }
        }
    });
});

setTimeout(function() { 
    var compareArr = localStorage.getItem("compareArr") !== null ? (localStorage.getItem("compareArr")).split(',') : [];
    if(compareArr.length > 0) {
        for(var i in compareArr) {
            $('input[id="' + compareArr[i] + '"]').prop('checked', true);
        }
        $('.compare-count').html(compareArr.length);
    }

    if(compareArr.length < 4 ) {
        $('.compare-notice-limit').hide();
        $('.compareCheckBox input:checkbox:not(:checked)').prop("disabled", false);
        $('.pd-info-wrapper .compare-container input').prop("disabled", false);
    } else {
        $('.compare-notice-limit').show();
        $('.compareCheckBox input:checkbox:not(:checked)').prop("disabled", true);
        $('.pd-info-wrapper .compare-container input').prop("disabled", true);
    }
    
}, 1000);


$('body').on('change','.compareCheckBox input, .compare-container input',function() {
    var isChecked = $(this).is(":checked");
    var currID = $(this).attr('id');
    var compareArr = localStorage.getItem("compareArr") !== null ? (localStorage.getItem("compareArr")).split(',') : [];
    
    if(compareArr.length < 4 && isChecked) {
        compareArr.push(currID);
        var compareArr = compareArr.join(',');
        localStorage.setItem('compareArr', compareArr);
    } else {
        for(var i in compareArr) {
            if(compareArr[i] == currID) {
                compareArr.splice(i,1);
            }
        }

        if(compareArr.length > 0) {
            var compareArr = compareArr.join(',');
            localStorage.setItem('compareArr', compareArr);
        } else {
            localStorage.removeItem('compareArr', compareArr);
        }
        
    }

    compareArr = localStorage.getItem("compareArr") !== null ? (localStorage.getItem("compareArr")).split(',') : [];
    if(compareArr.length > 0) {
        $('.compare-count').html(compareArr.length);
    } else {
        $('.compare-count').html('');
    }

    $('.compareCountSearch').html(compareArr.length);

    $('.compare-modal').addClass('active');
    
    setTimeout(function(){
        $('.compare-modal').removeClass('active');
    }, 1000);
        
    if(compareArr.length < 4) {
        $('.compareCheckBox input:checkbox').prop("disabled", false);
        $('.compare-notice-limit').hide();
    } else {
        $('.compareCheckBox input:checkbox:not(:checked)').prop("disabled", true);
        $('.compare-notice-limit').show();
    }
});

$('body').on('click','.compareRemoveBtn',function() {
    var currID = $(this).data('id');
    var compareArr = localStorage.getItem("compareArr") !== null ? (localStorage.getItem("compareArr")).split(',') : [];
    for(var i in compareArr) {
        if(compareArr[i] == currID) {
            compareArr.splice(i,1);
        }
    }

    if(compareArr.length > 0) {
        var compareArr = compareArr.join(',');
        localStorage.setItem('compareArr', compareArr);
    } else {
        localStorage.removeItem('compareArr', compareArr);
    }

    compareArr = localStorage.getItem("compareArr") !== null ? (localStorage.getItem("compareArr")).split(',') : [];
    if(compareArr.length > 0) {
        $('.compare-count').html(compareArr.length);

        var qString = [];
        for(var i in compareArr) {
            var stringVal = 'pid'+ i + '=' + compareArr[i];
            qString.push(stringVal);
        }

        qString = '?'+ qString.join('&');

        if (history.pushState) {
            var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + qString;
            window.history.pushState({path:newurl},'',newurl);
        }

        $(this).closest('.compareTile').remove();

    } else {
        $('.compare-count').html('');
        window.location.href = localStorage.getItem("backURL") ? localStorage.getItem("backURL") : $('.logo-home').attr('href');
    }

    $('.compareCountSearch').html(compareArr.length);

    $('.compare-count-container').removeClass('d-none');

    setTimeout(function(){
        $('.compare-count-container').addClass('d-none');
    }, 2000);
});

$('body').on('submit','.compare-products-form2',function(e) {
    e.preventDefault();
    localStorage.setItem('backURL', window.location.href);
    var url = $(this).attr('action');
    var url_string = window.location.href;
    var newurl = new URL(url_string);
    var partNumber = newurl.searchParams.get('partNumber');
    var oepn = newurl.searchParams.get('oepn');
    
    var compareArr = localStorage.getItem("compareArr") !== null ? (localStorage.getItem("compareArr")).split(',') : [];
    if(compareArr.length <= 1) {
        return false;
    } else {
        if(compareArr.length > 0) {
            var qString = [];
            for(var i in compareArr) {
                var stringVal = 'pid'+ i + '=' + compareArr[i];
                qString.push(stringVal);
            }

            qString = '?'+ qString.join('&');
            url = url + qString;
            if(partNumber) {
                url = url + '&partNumber=' + partNumber;
            }
            if(oepn) {
                url = url + '&oepn=' + oepn;
            }
            console.log(url);
            window.location.href = url;
        }
    }
})

$('body').on('click','.bulb-finder-signinbtn',function() {
    $('.bulb-finder-signinbtn').removeClass('active');
    $(this).addClass('active');
    var dataID = $(this).data('id');
    $('.bulb-finder-signinContainer').hide();
    $('#' + dataID).show();
});


$('body').on('submit', '#create-account form.registrationBulbFinder', function(e) {
    var form = $(this);
    e.preventDefault();
    var url = form.attr('action');
    form.spinner().start();
    var dataAction = form.data('action');
    var vehicleData = $('.vehicle-model-container .save').data('id');
    var vehicleDataString = '';
    for (var key in vehicleData) {
        if (vehicleData.hasOwnProperty(key)) {
            vehicleDataString += key + '=' + vehicleData[key]+'&';
        }
    }
    var currFormData = form.serialize();
        currFormData =vehicleDataString + currFormData;

    $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: currFormData,
        success: function (data) {
            form.spinner().stop();
            if (!data.success) {
                formValidation(form, data);
                console.log('error');
            } else {
                console.log('success');
                $('.save-vehicle-container .login-container').removeClass('active');
                $('.saved-vehicle-main-container ').addClass('active');
                $('.current-vehicle-container').hide();
                $.ajax({
                    type: 'get',
                    url: dataAction,
                    success: function (result) {
                        $('.getVehicles-container').html(result);
                    }
                });
            }
        },
        error: function (err) {
            form.spinner().stop();
        }
    });
    return false;
});

$('body').on('click','.find-bulb-btn',function(e) {
    e.preventDefault();

    var url = $(this).data('target');
    $.get(url, function (data, status) {
        if (status === 'success') {
            $('select[name="bulbFinderYear"]').val('');
            var bulbFinderMake = $('select[name="bulbFinderMake"]').empty();
            var bulbFinderModel = $('select[name="bulbFinderModel"]').empty();
            bulbFinderMake.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
            bulbFinderMake.attr('disabled', true);
            bulbFinderModel.append('<option selected="true" disabled>Please Select</option>').prop('selectedIndex', 0);
            bulbFinderModel.attr('disabled', true);
            resetValuesOnCarIdChange();
            $('input[name="carId"]').val('');
            $('input[name="oepn"]').val('');
            $('input[name="radio"]').prop('checked', false);


            $('#findbulbRedirect').attr('disabled', true);
            $('#findbulb').attr('disabled', true);
            $('.select-bulb-make').addClass('d-none');
            $('.select-bulb-model').addClass('d-none');
            $('.select-bulb-position').addClass('d-none');
            
            if ($('#findbulb').length > 0) {
                $('#findbulbRedirect').removeClass('d-none');
                $('#findvideoRedirect').removeClass('d-none');
                $('#findbulb').addClass('d-none');
            }

            if (localStorage.getItem('bulbFinderYear') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('bulbFinderMake') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('bulbFinderModel') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('bulbFinderLocations') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('bulbFinderPositions') !== null) {
                localStorage.removeItem('bulbFinderYear');
            }
            if (localStorage.getItem('backURL') !== null) {
                localStorage.removeItem('backURL');
            }

        }
    });

    window.location.href = $('#bulb-finder').attr('href');
});

if($('#cswidgetloaderjs').length > 0 && $('#cswidgetjs').length > 0) {
    setTimeout(function(){
        if(document.getElementById("cswidgetloaderjs")) {
            document.getElementById("cswidgetloaderjs").remove();
        }
        if(document.getElementById("cswidgetjs")) {
            document.getElementById("cswidgetjs").remove();       
        }
        

        if(!document.getElementById('cswidgetloaderjs')){
            var script = document.createElement('script');
                script.src='https://cdn.channelsight.com/widget/scripts/cswidget.loader.js';
                script.id="cswidgetloaderjs";
            document.head.appendChild(script);
        }
    }, 2000);
}

$(document).ajaxComplete(function() { 
    if($('#cswidgetloaderjs').length > 0 && $('#cswidgetjs').length > 0) {
        setTimeout(function(){
            if (document.getElementById("cswidgetloaderjs")) {
                document.getElementById("cswidgetloaderjs").remove();
            }
            if (document.getElementById("cswidgetjs")) {
                document.getElementById("cswidgetjs").remove();
            }
        
            if(!document.getElementById('cswidgetloaderjs')){
                var script = document.createElement('script');
                    script.src='https://cdn.channelsight.com/widget/scripts/cswidget.loader.js';
                    script.id="cswidgetloaderjs";
                document.head.appendChild(script);
            }
        }, 1000);
    }
});

$(document).ajaxComplete(function() { 
    var compareArr = localStorage.getItem("compareArr") !== null ? (localStorage.getItem("compareArr")).split(',') : [];
    if(compareArr.length > 0) {
        for(var i in compareArr) {
            $('input[id="' + compareArr[i] + '"]').prop('checked', true);
        }
        $('.compare-count').html(compareArr.length);
    }

    if(compareArr.length < 4 ) {
        $('.compareCheckBox input:checkbox:not(:checked)').prop("disabled", false);
        $('.pd-info-wrapper .compare-container input').prop("disabled", false);
    } else {
        $('.compareCheckBox input:checkbox:not(:checked)').prop("disabled", true);
        $('.pd-info-wrapper .compare-container input').prop("disabled", true);
    }
    
});

$(document).on('beforeChange', '.video, .thumbnail-container',function(event, slick, currentSlide, nextSlide){
    $('video').each(function() {
        $(this).get(0).pause();
    });
});

$(document).on('afterChange', '.video, .thumbnail-container',function(event, slick, currentSlide, nextSlide){
    if( $('.video .slick-active').find('video').length !== 0) {
        $('.video .slick-current video')[0].play();
    }
    $('.video video').on('ended',function(){ 
        if($('.video .slick-current').data('slick-index') < $('.video video').length -1) {
            $('.how-to-carousel-slider').slick('slickNext');
        }
    });
});

$('.video video').on('ended',function(){
    $('.video, .thumbnail-container').slick('slickNext');
});

$(document).on('click','.compareCheckBox .custom-checkbox label, .pd-info-wrapper .compare-container input',function() {
    var isDisabled = $(this).closest('.compareCheckBox').find('.custom-control-input').is(':disabled');
    if(isDisabled) {
        $('.compare-notice-modal').addClass('active');
        setTimeout(function(){
            $('.compare-notice-modal').removeClass('active');
        }, 2000);
    }
})

setTimeout(function(){
if($('.product-comparison').length > 0) {
    initKeywordsCollapse();
}
}, 500);

/**
 * Function to create request to populate email signup dropdowns
 * @param {*} lookUpType Lookup request
 * @returns {*} request RequestObject
 */
 function createRequestJoinList(lookUpType) {
    let request = {};
    // var radioButtonVal = $('input[name="radio"]:checked').val();
    let captchaResponse = $('input[name="g-recaptcha-response"]').val();
    if (captchaResponse) {
        request.captchaResponse = captchaResponse;
    }
    request.lookupType = lookUpType;
    let year = $('#jmlBulbFinderYear').val();
    let manufacturerId = $('#jmlBulbFinderMake').val();
    let pageloadID=$('#pageloadID').val();
    if(""==pageloadID){
        pageloadID=$('#pageloadID').val(getRandom()).val();
    }
    request.requestID=pageloadID+"|"+$('#requestID').val(getRandom()).val();
    switch (lookUpType) {
        case 'makes':
            request.constructionYear = year;
            break;
        case 'models':
            request.constructionYear = year;
            request.manufacturerId = manufacturerId;
            break;
        default:
        	throw new RangeError("Invalid lookUpType: "+lookUpType);
    }
    return request;
}

// On Email Signup year dropdown change functions
$('#jmlBulbFinderYear').on('change', function (e) {
    e.preventDefault();
    var $this = $(this).closest('.joinlist-bulb-finder-form');
    let bulbFinderMake = $this.find('#jmlBulbFinderMake').empty();
    let bulbFinderModel = $this.find('#jmlBulbFinderModel').empty();
    bulbFinderMake.append('<option selected="true" disabled>Make</option>').prop('selectedIndex', 0);
    bulbFinderModel.append('<option selected="true" disabled>Model</option>').prop('selectedIndex', 0);
    $this.find('#jmlBulbFinderModel').prop( "disabled", true );
    resetValuesOnCarIdChange($this);
    let captchaPromise = getNewToken('makes');
    captchaPromise.then(
        function (value) {
            if (value === 'success') {
                let makesReq = createRequestJoinList('makes');
                let url = $this.find('#jmlBulbFinderMake').data('url');
                $('.select-bulb-make').removeClass('d-none');
                ajaxCall(makesReq, 'dropdown', $this.find('#jmlBulbFinderMake'), url);
                bulbFinderMake.removeAttr('disabled');
            }
        }
    );
});

// On Email Signup Makes dropdown change functions
$('#jmlBulbFinderMake').on('change', function (e) {
    e.preventDefault();
    var $this = $(this).closest('.joinlist-bulb-finder-form');
    let bulbFinderModel = $this.find('#jmlBulbFinderModel').empty();
    bulbFinderModel.append('<option selected="true" disabled>Model</option>').prop('selectedIndex', 0);
    resetValuesOnCarIdChange($this);
    let captchaPromise = getNewToken('models');
    captchaPromise.then(
        function (value) {
            if (value === 'success') {
                let modelsReq = createRequestJoinList('models');
                let url = $this.find('#jmlBulbFinderMake').data('url');
                $('.select-bulb-model').removeClass('d-none');
                ajaxCall(modelsReq, 'dropdown', $this.find('#jmlBulbFinderModel'), url);
                bulbFinderModel.removeAttr('disabled');
            }
        }
    );
});

// On Email Signup Model dropdown change functions
$('#jmlBulbFinderModel').on('change', function (e) {
    e.preventDefault();
    $('.select-bulb-position').removeClass('d-none');
});

// click function on Change Link
$('[id=clearLink]').on('click', function (e) {
    e.preventDefault();
    
    if ($('.joinlist-bulb-finder-form .custom-select-group').hasClass('d-none')) {
        $('.joinlist-bulb-finder-form .custom-select-group').removeClass('d-none');

        $('#jmlBulbFinderYear').val('');
        let bulbFinderMake = $('#jmlBulbFinderMake').empty();
        let bulbFinderModel = $('#jmlBulbFinderModel').empty();
        bulbFinderMake.append('<option selected="true" disabled>Make</option>').prop('selectedIndex', 0);
        bulbFinderMake.attr('disabled', true);
        bulbFinderModel.append('<option selected="true" disabled>Model</option>').prop('selectedIndex', 0);
        bulbFinderModel.attr('disabled', true);
    } else {
        $('.joinlist-bulb-finder-form .custom-select-group').toggleClass('d-none');
    }
});


