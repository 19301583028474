'use strict';

var gtm = {
    getPidValue: function ($el) {
        var pid;
        if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
            pid = $($el).closest('.modal-content').find('.product-quickview').data('pid');
        } else if ($('.product-set-detail').length || $('.product-set').length) {
            pid = $($el).closest('.product-detail').find('.product-id').first()
                .text();
        } else if ($('.add-to-cart-global').length) {
            pid = $($el).data('pid');
        } else {
            pid = $('.product-detail:not(".bundle-item")').data('pid');
        }
        return pid;
    },
    url: '',
    getPageAction: function () {
        return $('.page').data('action');
    },
    requestProductDetails: function (data, targetData, method) {
        $.ajax({
            url: gtm.url,
            method: method,
            data: data,
            dataType: 'json',
            success: function (response) {
                gtm.processResponse(response.productDetails, targetData);
            },
            error: function () {
                console.log('Error in getting GTM data');
            }
        });
    },
    getProductIDS: function (elem) {
        var details = elem;
        var pids = [];
        details.each(function (i) {
            var pid = $(this).data('pid');
            pids.push(pid);
        });
        if (pids.length >= 0) {
            pids = pids.join(',');
        }
        return pids;
    },
    processResponse: function (response, targetData, targetKey) {
        try {
            if (response.length > 0) {
                var currTargetData = gtm.targetData[targetData];
                var evn = currTargetData.data.event;
                var targetObj = Object.create({});
                // deep clone target object
                $.extend(true, targetObj, currTargetData.data);
                if (typeof (response) === 'string') {
                    // append string response in target property
                    if (typeof (targetKey) !== 'undefined') {
                        gtm.getProperty(targetObj, currTargetData.keys)[targetKey] = response;
                    }
                } else {
                    // push product details
                    response.forEach(function (v) {
                        // clean product details
                        var detail = v;
                        // check if raw product details
                        if ('productName' in v) {
                            detail = productDetail(v);
                        }
                        gtm.getProperty(targetObj, currTargetData.keys).push(detail);
                    });
                }
                dataLayer.push(targetObj);
            }
        } catch (e) {
            console.log('GTM Process Response');
        }
    },
    init: function () {
        gtm.action.onload();
        var events = gtm.action.events;
        Object.keys(events).forEach(function (key) {
            if (typeof events[key] === 'function') {
                events[key]();
            }
        });
    },
    getProperty: function (targetObj, keyPath) {
        var keys = keyPath.split('.');
        if (keys.length == 0) return undefined;
        keys = keys.reverse();
        var subObject = targetObj;
        while (keys.length) {
            var k = keys.pop();
            if (!subObject.hasOwnProperty(k)) {
                return undefined;
            }
            subObject = subObject[k];
        }
        return subObject;
    }, // function coming from backend
    targetData: gtmSchema, // object coming from backend
    ajaxHook: function (callback) {
        $(document).ajaxComplete(function (e, request, settings) {
            try {
                var response = JSON.parse(request.responseText);
                callback(response);
                $(e.currentTarget).off('ajaxComplete');
            } catch (e) {
                console.log('Process Hook');
            }
        });
        $(document).ajaxError(function (e, request, settings) {
            try {
                console.log('Error in getting GTM data');
                $(e.currentTarget).off('ajaxError');
            } catch (e) {
                console.log('Process Hook');
            }
        });
        return false;
    },
    pushDataLayer: function (eventAction, eventLabel, eventCategory) {
        var gtmData = {};
        gtmData.event = 'gaEvent';
        gtmData.eventCategory = eventCategory;
        gtmData.eventAction = eventAction.trim();
        if (eventLabel !== '') {
            gtmData.eventLabel = eventLabel.trim();
        }
        dataLayer.push(gtmData);
    },
    emailSubscribe: function (eventLabel) {
        var gtmData = {};
        gtmData.event = 'emailSubscribed';
        gtmData.eventCategory = 'email-subscription';
        gtmData.eventAction = 'subscribed';
        gtmData.eventLabel = eventLabel;
        dataLayer.push(gtmData);
    },
    getBulbFinderDropDownValues: function (videos) {
        var year;
        var makeName;
        var modelName;
        if (videos) {
            year = $('#yearSelector option:selected').text().trim();
        } else {
            year = $('#bulbFinderYear option:selected').text().trim();
        }
        if (videos) {
            makeName = $('#makeSelector option:selected').text().trim();
        } else {
            makeName = $('#bulbFinderMake option:selected').text().trim();
        }
        if (videos) {
            modelName = $('#modelSelector option:selected').text().trim();
        } else {
            modelName = $('#bulbFinderModel option:selected').text().trim();
        }
        var arr = [];
        arr.push(makeName);
        arr.push(modelName);
        arr.push(year);
        if (!videos) {
            var position = $('#bulbFinderPositions option:selected').text().trim();
            var trimDetails = $('#bulbFinderNotes option:selected').text().trim();
            if (trimDetails === 'Please Select') {
                trimDetails = 'no trim selection';
            };
            arr.push(trimDetails);
            arr.push(position);
        }
        var gtmData = arr.join(',');
        return gtmData;
    },
    bulbOption: function () {
        var gtmData = {};
        gtmData.event = 'gaEvent';
        gtmData.eventCategory = 'bulb options';
        dataLayer.push(gtmData);
    },
    retailerTrack: function (eventAction, eventCategory, event, eventLabel, eventSelection) {
        var gtmData = {};
        gtmData.eventLabel = eventLabel;
        gtmData.eventAction = eventAction;
        if (eventSelection !== '')
        {
            gtmData.eventSelection = eventSelection;
        }
        gtmData.eventCategory = eventCategory;
        gtmData.event = event;
        dataLayer.push(gtmData);
    },
    socialLink: function (eventAction, eventLabel) {
        var gtmData = {};
        gtmData.eventAction = eventAction;
        gtmData.eventLabel = eventLabel;
        gtmData.event = 'social';
        gtmData.eventCategory = 'social';
        dataLayer.push(gtmData);
    },
    saveVehicle: function (eventAction) {
        var gtmData = {};
        if (eventAction !== '') {
            gtmData.eventAction = eventAction;
        }
        gtmData.event = 'gaEvent';
        gtmData.eventCategory = 'save vehicle';
        dataLayer.push(gtmData);
    },
    commonEvents: function (event, eventCategory, eventAction, eventLabel) {
        var gtmData = {};
        if (event !== '') {
            gtmData.event = event;
        };
        if (eventCategory !== '') {
            gtmData.eventCategory = eventCategory;
        };
        if (eventAction !== '') {
            gtmData.eventAction = eventAction;
        };
        if (eventLabel !== '') {
            gtmData.eventLabel = eventLabel;
        }
        dataLayer.push(gtmData);

    },
    getPageType : function (actionType){
        var type = '';
        var metaTitle = document.title ? document.title.split('|')[0].trim() : '';
        switch (actionType) {
            case 'Product-Show':
                type = 'product:'+metaTitle;
                break;
            case 'Search-Show':
                type = 'search:'+metaTitle;
                break;
            case 'category':
                type = 'category:'+metaTitle;
                break;
            case 'Cart-Show':
                type = 'cart:'+metaTitle;
                break;
            case 'Order-Confirm':
                type = 'orderconfirmation:'+metaTitle;
                break;
            case 'Account-Show':
                type = 'accountlanding:'+metaTitle;
                break;
            case 'Account-EditProfile':
                type = 'editprofile:'+metaTitle;
                break;
            case 'Account-EditPassword':
                type = 'editpassword:'+metaTitle;
                break;
            case 'Address-AddAddress':
                type = 'addaddress:'+metaTitle;
                break;
            case 'PaymentInstruments-AddPayment':
                type = 'addcreditcard:'+metaTitle;
                break;
            case 'shipping':
                type = 'checkout:shipping';
                break;
            case 'billing':
                type = 'checkout:billing';
                break;
            case 'orderreview':
                type = 'checkout:orderreview';
                break;
            case 'homepage':
                type = 'home:'+metaTitle;
                break;
            case 'Page-Show':
                type = 'content:'+metaTitle;
                break;
            case 'BulbFinder-Show':
            case 'bulb-finder':
            case 'auto-bulbs':
                type = 'search:'+metaTitle;
                break;
            case 'Login-Show':
                type = 'account:Account Login';
                break;
            case 'Order-Track':
                type = 'ordertrack:'+metaTitle;
                break;
            case 'Home-ErrorNotFound':
                type = 'error:'+metaTitle;
                break;
            default:
                type = 'content:'+metaTitle;
                break;
        }
        return type;
    },
    updatePageType : function (actionType){
        var action = gtm.getPageType(actionType);
        for (var i = 0; i < dataLayer.length; i++) {
            if(dataLayer[i] && dataLayer[i].event === 'pageLoad'){
                if(window.categoryPageType){
                    action = window.categoryPageType +':'+ document.title.split('|')[0].trim();
                }
                dataLayer[i].pageName = action;
                dataLayer[i].pageType = action.split(':')[0];
            }
            if(dataLayer[i] && dataLayer[i].event === 'productImpressions' && window.ProductRecommandation){
                var  impressions = dataLayer[i].ecommerce.impressions;
                for (var j = 0; j < impressions.length; j++) {
                    if ($('.recommendations .product-grid').length > 0) {
                        dataLayer[i].ecommerce.impressions[j].list = action.split(':')[0] +':'+ window.ProductRecommandation;
                    } else if ($('.carousel-heading').length > 0) {
                        dataLayer[i].ecommerce.impressions[j].list = action.split(':')[0] +':'+ $('.carousel-heading').text().trim();
                    }
                }
            }
        }
    },
    action: {
        onload: function () {
            // add some onload script here
            $(document).ready(function () {
                var actionType = $('.page').data('action');
                if(window.userInfo && actionType){
                    gtm.updatePageType(actionType);
                }
                if($('.card-banner-tile').length > 0 && $('.product-tile').length > 0){
                    gtm.updatePageType('category');
                }
            });
        },
        events: {
            // add all event listener triggered thru click
            showMore: function () {
                $('.container').on('click', '.show-more button', function (e) {
                    var showMoreUrl = $(this).data('url');
                    var queryStr = showMoreUrl.split('?')[1];
                    gtm.url = gtmUrls.searchDetails + '?' + queryStr;
                    gtm.requestProductDetails({}, 'PLP', 'GET');
                });
            },
            addToCart: function () {
                var selectedVariantId = '';
                var selectedQuantity = 1;
                /**
                 * Create additional listener to existing add to cart function.
                 */
                $('body').on('product:beforeAddToCart', function (e, data) {
                    selectedVariantId = gtm.getPidValue($(data));
                });
                $('body').on('product:afterAddToCart', function (e, data) {
                    var items = data.cart.items;
                    var productDetails = [];
                    items.forEach(function (item) {
                        // attach selected item
                        if (item.id === selectedVariantId) {
                            gtm.url = gtmUrls.productDetails;
                            gtm.requestProductDetails({ pids: item.id,quantity : 1 }, 'AddToCart', 'POST');
                        }
                    });
                });
            },
            removeLineItemfromCart: function () {
                $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
                    e.preventDefault();
                    var pid = $(this).data('pid');
                    var uuid = $(this).data('uuid');
                    var quantity = $('#quantity-'+uuid).data('pre-select-qty');
                    gtm.ajaxHook(function (req) {
                        gtm.url = gtmUrls.productDetails;
                        gtm.requestProductDetails({ pids: pid, quantity :quantity }, 'RemoveLineItemfromCart', 'POST');
                    });
                });
            },
            cartUpdateQty: function () {
                $('body').on('change', '.quantity-form > .quantity', function () {
                    var _this = $(this);
                    var preQty = new Number(_this.attr('data-pre-select-qty'));
                    var newQty = new Number(_this.val());
                    var pid = _this.data('pid');
                    var quantity = newQty - preQty;
                    var target = 'CartIncreaseQty';
                    if (preQty > newQty) {
                        target = 'CartDecreaseQty';
                    }
                    if(target === 'CartIncreaseQty'){
                        gtm.ajaxHook(function (response) {
                            var items = response.items;
                            var productDetails = [];
                            items.forEach(function (item) {
                                if (item.id === pid) {
                                    item.quantity = quantity;
                                    var detail = productDetail(item);
                                    productDetails.push(detail);
                                }
                            });
                            gtm.processResponse(productDetails, target);
                        });
                    }
                    _this.attr('data-pre-select-qty', newQty);
                });
            },
            checkout: function () {
                $('button.submit-shipping').click(function (e) {
                    e.preventDefault();
                    gtm.ajaxHook(function (response) {
                        if (!response.error) {
                            var customerType = $('#checkout-main').data('customer-type');
                            gtm.updatePageType('billing');
                            // add payment
                            if(customerType && customerType === 'guest'){
                                gtm.processResponse(customerType, 'PaymentOption', 'option'); 
                            }else{
                                gtm.processResponse(response.order.items.items, 'Payment');
                            }
                        }
                    });
                });
                $('button.submit-payment').click(function (e) {
                    e.preventDefault();
                    gtm.ajaxHook(function (response) {
                        var customerType = $('#checkout-main').data('customer-type');
                        if (!response.error) {
                            gtm.updatePageType('orderreview');
                            if ( customerType && customerType === 'guest' ) {
                                gtm.processResponse(customerType, 'OrderReviewOption', 'option'); 
                            } else {
                                gtm.processResponse(response.order.items.items, 'OrderReview');
                            }    
                            var addToEmailList = $('#add-to-email-list').val();
                            if (addToEmailList.length > 0 && addToEmailList) {
                                var eventLabel = 'checkout-form';
                                gtm.emailSubscribe(eventLabel);
                            }
                        }
                    });
                });
                if($('#checkout-main').length > 0){
                    var customerType = $('#checkout-main').data('customer-type');
                    var checkoutstage = $('#checkout-main').data('checkout-stage');
                    if(checkoutstage && checkoutstage === 'shipping'){
                        gtm.updatePageType('shipping');
                        if(customerType && customerType === 'guest'){
                            gtm.processResponse(customerType, 'ShippingOption', 'option');
                        }
                    }
                }
            },
            bulbfinder: function () {
                var eventCategory = 'bulb finder';
                $(document).on('change', '#bulbFinderYear', function () {
                    var action = 'year';
                    var year = $('#bulbFinderYear option:selected').text().trim();
                    gtm.pushDataLayer(action, year, eventCategory);
                });
                $(document).on('change', '#yearSelector', function () {
                    var action = 'year';
                    var year = $('#yearSelector option:selected').text().trim();
                    gtm.pushDataLayer(action, year, 'install video lookup');
                });
                $(document).on('change', '#bulbFinderMake', function () {
                    var action = 'make';
                    var makeName = $('#bulbFinderMake option:selected').text();
                    gtm.pushDataLayer(action, makeName, eventCategory);
                });
                $(document).on('change', '#makeSelector', function () {
                    var action = 'make';
                    var makeName = $('#makeSelector option:selected').text();
                    gtm.pushDataLayer(action, makeName, 'install video lookup');
                });
                $(document).on('change', '#bulbFinderModel', function () {
                    var action = 'model';
                    var modelName = $('#bulbFinderModel option:selected').text();
                    gtm.pushDataLayer(action, modelName, eventCategory);
                });
                $(document).on('change', '#modelSelector', function () {
                    var action = 'model';
                    var makeName = $('#modelSelector option:selected').text();
                    gtm.pushDataLayer(action, makeName, 'install video lookup');
                });
                $(document).on('change', '#bulbFinderPositions', function () {
                    var action = 'position';
                    var position = $('#bulbFinderPositions option:selected').text();
                    gtm.pushDataLayer(action, position, eventCategory);
                });
                $(document).on('change', '#bulbFinderLocations', function () {
                    var action = 'position';
                    var location = $('#bulbFinderLocations option:selected').text();
                    gtm.pushDataLayer(action, location, eventCategory);
                });
                $(document).on('click', '#findbulbRedirect', function (e) {
                    e.preventDefault();
                    var action = 'full search submit';
                    var details = gtm.getBulbFinderDropDownValues();
                    gtm.pushDataLayer(action, details, eventCategory);
                });
                $(document).on('click', '#findvideoRedirect', function (e) {
                    e.preventDefault();
                    var action = 'full search submit';
                    var details = gtm.getBulbFinderDropDownValues('findvideoRedirect');
                    gtm.pushDataLayer(action, details, 'install video lookup');
                });
                $(document).on('click', '#findbulb', function (e) {
                    e.preventDefault();
                    var action = 'full search submit';
                    var details = gtm.getBulbFinderDropDownValues();
                    gtm.pushDataLayer(action, details, eventCategory);
                });
                $(document).on('click', '#findBulbByPartNumberRedirect', function (e) {
                    e.preventDefault();
                    var action = 'full search submit by part number';
                    var partNumber = $('#partNumber').val();
                    gtm.pushDataLayer(action, partNumber, eventCategory);
                });
                $(document).on('click', '#findbulbByPartNumber', function (e) {
                    e.preventDefault();
                    var action = 'full search submit by part number';
                    var partNumber = $('#partNumber').val();
                    gtm.pushDataLayer(action, partNumber, eventCategory);
                });
                $(document).on('click', '#resetLink', function (e) {
                    e.preventDefault();
                    var action = 'reset';
                    gtm.pushDataLayer(action, '', eventCategory);
                });
                $('#bulbFinderNotes').on('change', function (e) {
                    e.preventDefault();
                    var action = 'trim';
                    var eventLabel = $('#bulbFinderNotes option:selected').text().trim();
                    gtm.pushDataLayer(action, eventLabel, eventCategory);
                });
                $('*[data-target="#bulbfinderCollapse"]').on('click', function (e) {
                    e.preventDefault();
                    var action = 'which bulb do I need';
                    gtm.pushDataLayer(action, '', eventCategory);
                });
                $(document).on('click', '#bulbOptionsBtn', function (e) {
                    gtm.bulbOption();
                });
                $(document).on('click', '#findRetailerResults', function (e) {
                    var eventLabel = $('#findRetailerResults').data('product-sku');
                    var elem = $('.pdp-main').length;
                    var eventAction = elem > 0 ? 'pdp' : 'plp';
                    var eventCategory = 'find a retailer';
                    var event = 'findRetailer';
                    gtm.retailerTrack(eventAction, eventCategory, event, eventLabel, '');
                });
                $(document).on('click', '.social-links', function (e) {
                    var eventAction = e.target.title.toLowerCase();
                    var pathName = document.location.pathname;
                    var eventLabelList = pathName.split('/');
                    var prefix = '/';
                    var eventLabel;
                    for (var i = eventLabelList.length - 1; i >= 0; i -= 1) {
                        if (eventLabelList[i].length > 0) {
                            eventLabel = prefix.concat(eventLabelList[i]);
                            if (i !== eventLabelList.length - 1) {
                                eventLabel = eventLabel.concat(prefix);
                            };
                            break;
                        }
                    };
                    gtm.socialLink(eventAction, eventLabel);
                });
                $(document).on('click', '#bulbSaveVehicleBtn', function (e) { 
                    var eventAction = '';
                    gtm.saveVehicle(eventAction);
                });
                $(document).on('click', '.find-bulb-btn', function (e) {
                    var event = 'gaEvent';
                    var eventCategory = 'find new bulb';
                    var eventAction = $('.product-detail').data('pid');
                    gtm.commonEvents(event, eventCategory, eventAction, '');
                });
                $(document).on('click', '.edit-vehicle', function (e) {
                    var event = 'gaEvent';
                    var eventCategory = 'edit vehicle';
                    gtm.commonEvents(event, eventCategory, '', '');
                });
                $(document).on('click', '.cs_content-buylocally-details', function (e) {
                    var target = e.target;
                    var eventAction = 'buyOffline_' + $(e.target).closest('*[data-retailername]').data('retailername');
                    var eventCategory = 'find a retailer results';
                    var event = 'findRetailerResults';
                    var eventLabel = $('.add-to-cart').data('pid');
                    var eventSelection;
                    if (target.className.includes('direction')) {
                        eventSelection = 'direction';
                    } else if (target.className.includes('online')) {
                        eventSelection = 'site';
                    } else {
                        eventSelection = 'phone';
                    }
                    gtm.retailerTrack(eventAction, eventCategory, event, eventLabel, eventSelection);
                });
                $(document).on('click', 'a.cs_buynow-button', function (e) {
                    var target = e.target;
                    var eventAction = 'buyOnline_'+$(e.target).closest("*[data-retailername]").data("retailername");
                    var eventCategory = 'find a retailer results';
                    var event = 'findRetailerResults';
                    var eventLabel = $('.add-to-cart').data('pid');
                    var eventSelection = 'site';
                    gtm.retailerTrack(eventAction, eventCategory, event, eventLabel, eventSelection);
                });
                $(document).on('click', '.viewDetails', function (e) {
                    var event = 'gaEvent';
                    var eventCategory = 'view Details';
                    var eventAction = e.target.nextElementSibling.lastElementChild.children[0].id;
                    gtm.commonEvents(event, eventCategory, eventAction, '');
                });
                $(document).on('click', '.compare-products-form2', function (e) {
                    var event = 'gaEvent';
                    var eventCategory = 'compare';
                    var eventAction = localStorage.getItem('compareArr');
                    gtm.commonEvents(event, eventCategory, eventAction, '');
                });
                $(document).on('click', '.banner-video-link', function (e) {
                    var event = 'videoInteraction';
                    var eventAction = 'start';
                    var eventCategory = 'video';
                    var eventLabel = this.title;
                    gtm.commonEvents(event, eventCategory, eventAction, eventLabel);
                });
                $(document).on('ended', 'video', function (e) {
                    var event = 'videoInteraction';
                    var eventAction = 'complete';
                    var eventCategory = 'video';
                    gtm.commonEvents(event, eventCategory, eventAction, '');
                });
                $('body').on('submit', '#create-account form.registrationBulbFinder', function(e) {
                    var eventAction = 'account created';
                    gtm.saveVehicle(eventAction);
                });
                $('body').on('submit', '#sign-in-bp form.bulb-finder-login', function(e) {
                    var eventAction = 'signed in';
                    gtm.saveVehicle(eventAction);
                });
                $(document).on('click', '#bulbVideoBtn', function (e) {
                    var event = 'gaEvent';
                    var eventCategory = 'how to install';
                    var eventAction = gtm.getBulbFinderDropDownValues();
                    gtm.commonEvents(event, eventCategory, eventAction, '');
                });
            },

            productVideoClick: function () {
                $(document).on('click', '.carousel .product-video, .installation-content-block .video-modal-trigger', function () {
                    dataLayer.push({
                        event: 'gaEvent',
                        eventCategory: gtmSchema.PDP.data.ecommerce.detail.products[0].category,
                        eventAction: 'installation guide: video',
                        eventLabel: gtmSchema.PDP.data.ecommerce.detail.products[0].id
                    });
                });
            },
            productTileClick: function () {
                $(document).on('click','.image-container,.pdp-link,#prodViewDetails', function () {
                    var product = $(this).closest(".product-tile").data('product');
                    if (product) {
                        var productDetails = [];
                        var productPrice = product.price ? product.price.split('$')[1] : '';
                        var detail = {
                            id: product.id, name: product.productName, brand: '', price: productPrice,
                            category: product.category,position: product.position,variant:product.variant
                        };
                        var actionCat = product.actionField;
                        if (product.actionField && product.actionField === 'Recommandation'){
                            var actionType = $('.page').data('action');
                            var pageType = gtm.getPageType(actionType);
                            if ($('.recommendations .product-grid').length > 0) {
                                actionCat = pageType.split(':')[0] +':'+ window.ProductRecommandation;
                            } else if ($('.carousel-heading').length > 0) {
                                actionCat = pageType.split(':')[0] +':'+ $('.carousel-heading').text().trim();
                            }
                        }
                        gtmSchema.ProductClick.data.ecommerce.click.actionField.list = actionCat;
                        productDetails.push(detail);
                        gtm.processResponse(productDetails, 'ProductClick');
                    }
                });
            },
            emailSignUp: function () {
                $('.subscribe-email').on('click', function () {
                    var email = $('input:text[name=hpEmailSignUp]').val();
                    if ($(this).closest('#signUpModal').length) {
                        email = $('#signUpModal').find('input:text[name=hpEmailSignUp]').val();
                    }
                    if (email.length > 0 && email) {
                        var eventLabel = $(this).attr('data-source');
                        gtm.emailSubscribe(eventLabel);
                    }
                });
                $('.registration').on('submit', function () {
                    if ($('#add-to-email-list')[0].checked) {
                        var eventLabel = 'account-form';
                        gtm.emailSubscribe(eventLabel);
                    }
                });
            }
        } // end
    }
};
module.exports = function () { gtm.init(); };
