'use strict';

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

module.exports = function () {
    var showSignedUpModal = getCookie('showSignedUpModal') === '' ? 0 :  parseInt(getCookie('showSignedUpModal'));
    var $signUpModal = $('#signUpModal');
    var $visitedSite = getCookie('__visited');

    if ($visitedSite !== '1') {
        
        showSignedUpModal += 1;
        setCookie('showSignedUpModal', showSignedUpModal, 7);
    
        if(showSignedUpModal === 2) {
    
            setTimeout(function(){
                $signUpModal.modal('show');

                if (localStorage.bulbFinderPositions !== undefined && localStorage.bulbFinderMake !== undefined && localStorage.bulbFinderModel !== undefined) {
                    var jmlBulbFinderYearValue = $('.js-bulbfinder-selected-year').text().trim();
                    var jmlBulbFinderMakeValue = $('.js-bulbfinder-selected-make').text().trim();
                    var jmlBulbFinderModelValue = $('.js-bulbfinder-selected-model').text().trim();
                    
                    if (jmlBulbFinderYearValue !== '' && jmlBulbFinderMakeValue !== '' && jmlBulbFinderModelValue !== '') {
                        $('.bulbFinder-search-result span').text(jmlBulbFinderYearValue + ' ' + jmlBulbFinderMakeValue + ' ' + jmlBulbFinderModelValue);
                        $('.bulbFinder-search-result').removeClass('d-none');
                        $('.joinlist-bulb-finder-form .custom-select-group').addClass('d-none');
                    } else {
                        $('.joinlist-bulb-finder-form .custom-select-group').removeClass('d-none');
        
                        if (!$('.bulbFinder-search-result').hasClass('d-none')) {
                            $('.bulbFinder-search-result').addClass('d-none');
                        }
                    }
                }
                setCookie('__visited', 1, 7);
            }, 5000);
    
        }            
    }

    $('#signUpModal input[name="hpEmailSignUp"]').off('keyup').on('keyup', function(e){
        var $parent = $(this).closest('#signUpModal');
        var $submit = $parent.find('.subscribe-email');
        
        if(e.which === 13) {
            $submit.trigger('click');
        }
    })
}